import React, { useCallback, useEffect, useState } from 'react';
import { showToast } from 'components/atomic/Toast/Toast';
import { useGlobal } from 'context/global/GlobalContext';
import { getRegisterText } from 'utils/getRegisterText';
import { useTranslation } from 'react-i18next';
import { isPreviewMode } from 'utils/preview';
import { Link } from 'react-router-dom';
import {
  ModuleInfoType,
  PurchaseTemplate,
  SweepstakesModuleType,
} from 'types/ProductDetailsType';
import Button from 'components/atomic/Button';
import HtmlWrapper from 'components/wrappers/HtmlWrapper';
import ConsentCheck from 'components/atomic/ConsentCheck/ConsentCheck';

type PreRegistrationProps = {
  html: string | null | undefined;
  brandName: string | undefined;
  productName?: string;
  slug: string | null;
  brandTheme: string;
  registrationType: any;
  register: any;
  hideAnimation?: boolean;
  enablePurchaseDetails?: boolean;
  purchaseTemplate?: PurchaseTemplate;
  customCallToAction?: string;
  customLegalText?: string;
};

const PreRegistration: React.FC<PreRegistrationProps> = ({
  html,
  slug,
  register,
  brandName,
  productName,
  customLegalText,
  registrationType,
  customCallToAction,
  hideAnimation = false,
}) => {
  const [emailConsentError, setEmailConsentError] = useState<boolean>(false);
  const [privacyConsentError, setPrivacyConsentError] =
    useState<boolean>(false);
  const {
    productDetails,
    emailConsent,
    toggleEmailConsent,
    privacyConsent,
    togglePrivacyConsent,
  } = useGlobal();
  const { t } = useTranslation('translation', {
    keyPrefix: 'drawers.authDrawer',
  });

  const emailConsentCheck = productDetails?.registration.consentCheck;
  const emailConsentDetails = productDetails?.registration.consentDetail;
  const privacyConsentCheck = productDetails?.registration.privacyConsent;
  const privacyConsentDetails =
    productDetails?.registration.privacyConsentDetail;

  const handleRegister = useCallback(() => {
    if (emailConsentCheck) {
      if (emailConsentDetails?.requireConsent && !emailConsent) {
        setEmailConsentError(true);
        showToast({ message: t('emailConsentRequired'), type: 'error' });
        return;
      }
    }
    if (privacyConsentCheck) {
      if (privacyConsentDetails?.requireConsent && !privacyConsent) {
        setPrivacyConsentError(true);
        showToast({ message: t('privacyConsentRequired'), type: 'error' });
        return;
      }
    }
    register();
  }, [
    t,
    register,
    emailConsentDetails,
    emailConsentCheck,
    emailConsent,
    privacyConsentDetails,
    privacyConsentCheck,
    privacyConsent,
  ]);

  const getSweepstakesGameRules = () => {
    if (productDetails) {
      let sweepstakesModule: ModuleInfoType | undefined =
        productDetails?.modules?.find(
          (module) => module.type === 'SWEEPSTAKES_MODULE'
        );
      if (sweepstakesModule) {
        let sweepstakesModuleInfo: SweepstakesModuleType =
          sweepstakesModule.moduleInfo as SweepstakesModuleType;
        return sweepstakesModuleInfo.gameRules ? true : false;
      } else return false;
    } else return false;
  };

  useEffect(() => {
    toggleEmailConsent(!!emailConsentDetails?.defaultChecked);
  }, [emailConsentDetails?.defaultChecked, toggleEmailConsent]);

  useEffect(() => {
    togglePrivacyConsent(!!privacyConsentDetails?.defaultChecked);
  }, [privacyConsentDetails?.defaultChecked, togglePrivacyConsent]);

  return (
    <div className='w-full flex flex-col items-center justify-start gap-5 py-2 overflow-auto overflow-x-hidden'>
      {html && <HtmlWrapper html={html} hideAnimation={hideAnimation} />}
      <div className='flex px-4' style={{ borderTop: '2px solid #E7EAEB' }}>
        {customLegalText ? (
          <HtmlWrapper
            html={customLegalText}
            styles='mt-4 [&_p]:text-left [&_p]:text-xxs [&_p]:text-darkGray'
          />
        ) : (
          <p className='mt-4 text-left text-xxs text-darkGray'>
            {t('termsAndConditions.part1')}
            {brandName}
            {t('termsAndConditions.part2')}
            {t('termsAndConditions.brijBrand')}
            <Link
              to={
                isPreviewMode()
                  ? `/c/${slug}`
                  : productDetails
                  ? `/p/${slug}`
                  : '/p/BRIJ'
              }
              className='underline cursor-pointer focus-visible:outline focus-visible:outline-black/60'
            >
              {t('termsAndConditions.linkText')}
            </Link>
            {getSweepstakesGameRules() && ` ${t('and')} `}
            {getSweepstakesGameRules() && (
              <Link
                to={
                  isPreviewMode()
                    ? `/c/${slug}`
                    : productDetails
                    ? `/s/${slug}`
                    : '/s/BRIJ'
                }
                className='underline cursor-pointer focus-visible:outline focus-visible:outline-black/60'
              >
                {t('sweepstakesRules')}
              </Link>
            )}
            {'.'}
            {t('termsAndConditions.part3')}
            <a
              target='_blank'
              rel='noreferrer'
              className='focus-visible:outline focus-visible:outline-black/60'
              href={`mailto:support@brij.it?subject=Help with ${brandName} ${productName} (${slug})`}
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
            >
              {t('termsAndConditions.helpEmail')}
            </a>
            {'.'}
          </p>
        )}
      </div>
      {emailConsentCheck || privacyConsentCheck ? (
        <ConsentCheck
          enabled={[emailConsentCheck || false, privacyConsentCheck || false]}
          errors={[emailConsentError, privacyConsentError]}
          texts={[
            emailConsentDetails?.text || '',
            privacyConsentDetails?.text || '',
          ]}
          toggleConsentChecks={[toggleEmailConsent, togglePrivacyConsent]}
          consentChecks={[emailConsent, privacyConsent]}
        />
      ) : null}
      <div className='relative w-full px-0.5'>
        <Button
          variant='black'
          onClick={handleRegister}
          title={getRegisterText(registrationType, customCallToAction)}
        />
      </div>
    </div>
  );
};

export default PreRegistration;
