import React, { useState } from 'react';
import { useGlobal } from 'context/global/GlobalContext';
import { ReactComponent as ExternalLink } from 'assets/icons/svg/external-link.svg';
import { validateSocials } from 'utils/validateSocials';
import { getImageMeta } from 'utils/productUtils';
import { SocialsType } from 'types/SocialsType';
import { useTranslation } from 'react-i18next';
import {
  CustomModuleData,
  WebsiteModuleData,
  ExperienceModuleData,
  DigitalModuleInfoType,
  ExperienceIndexModuleData,
  PossibleDigitalModulesType,
} from 'types/DigitalHubTypes';
import Button from 'components/atomic/Button';
import SearchBar from 'components/atomic/SearchBar';
import useRedirectLink from 'hooks/useRedirectLink';
import ProgressiveImage from 'react-progressive-image';
import SocialIcons from 'components/atomic/SocialIcons';
import HtmlWrapper from 'components/wrappers/HtmlWrapper';
import ProductButton from 'components/atomic/ProductButton';

type DigitalHubProps = {
  modules: any;
  dimBackground: boolean;
  logo: JSX.Element | null;
  socials: SocialsType | null;
  buttonStyle: string | undefined;
};

const DigitalHub: React.FC<DigitalHubProps> = ({
  logo,
  modules,
  socials,
  buttonStyle,
  dimBackground,
}) => {
  const [renderOptimizedMedia, setRenderOptimizedMedia] = useState(true);
  const redirectToExternalLink = useRedirectLink();
  const { desktopView } = useGlobal();
  const { t } = useTranslation('translation', {
    keyPrefix: 'digitalHub',
  });

  const getCornerRadius = () => {
    if (buttonStyle === 'largeCorner') return 'rounded-[50px]';
    else if (buttonStyle === 'smallCorner') return 'rounded-[10px]';
    else if (buttonStyle === 'noCorner') return 'rounded-none';
    else return 'rounded-[50px]';
  };

  return (
    <>
      {dimBackground && (
        <div className='absolute top-0 left-0 w-full h-full bg-black/50 duration-300' />
      )}
      <div
        className={`absolute top-0 left-0 w-full h-full flex flex-col justify-between items-stretch ${
          logo && !desktopView
            ? 'max-h-[calc(100%-5.25rem)] mt-[5.25rem]'
            : `${desktopView ? 'max-h-full pt-3' : 'max-h-full mt-0'}`
        }`}
      >
        {logo && desktopView && <div className='fixed mt-2 ml-16'>{logo}</div>}
        <div
          className={`
            relative w-full h-full flex flex-col px-5 gap-3 items-stretch flex-shrink-0 overflow-auto 
            ${
              socials ? 'max-h-[calc(100%-5.25rem)] pb-3.5' : 'max-h-full pb-6'
            } 
            ${desktopView ? 'mx-auto max-w-[50%]' : ''} 
            ${logo ? 'pt-0' : 'pt-4'}
          `}
        >
          {modules.map((module: DigitalModuleInfoType) => {
            switch (module.type) {
              case PossibleDigitalModulesType.CUSTOM_CONTENT:
                const content: CustomModuleData =
                  module.moduleData as CustomModuleData;
                return (
                  <HtmlWrapper
                    background={
                      content.showBackground
                        ? content.backgroundColor
                        : 'transparent'
                    }
                    cornerStyle={buttonStyle}
                    html={content.customContent}
                    styles={`
                      w-full flex flex-col gap-1 
                      ${content.showBackground ? 'py-4 px-6' : 'p-4'} 
                      ${content.showBackground ? 'shadow-md' : 'shadow-none'}
                    `}
                  />
                );
              case PossibleDigitalModulesType.WEBSITE_LINK:
                const link: WebsiteModuleData =
                  module.moduleData as WebsiteModuleData;

                let { mediaToUse, mediaType } = getImageMeta(
                  link?.image || '',
                  renderOptimizedMedia
                );
                return (
                  <Button
                    variant='custom'
                    title={link.text}
                    color={link.color}
                    background={link.backgroundColor}
                    styles='!flex-shrink-0 !px-16 !h-productButton'
                    onClick={() =>
                      redirectToExternalLink(
                        link.linkURL.includes('https://') ||
                          link.linkURL.includes('http://')
                          ? link.linkURL
                          : `https://${link.linkURL}`,
                        '_blank'
                      )
                    }
                    icon={
                      <ExternalLink
                        width={28}
                        fill={link.color}
                        className='absolute right-4'
                      />
                    }
                    image={
                      link.image ? (
                        mediaType.includes('mp4') ||
                        mediaType.includes('webm') ? (
                          <video
                            loop
                            muted
                            autoPlay
                            width='100%'
                            height='100%'
                            controls={false}
                            playsInline={true}
                            className={`absolute left-1.5 w-[54px] h-[54px] self-center justify-self-start object-cover ${getCornerRadius()}`}
                            onError={(e) => {
                              e.currentTarget.src = link?.image || ''; // replace with your fallback video URL
                            }}
                          >
                            <source src={mediaToUse} type={mediaType} />
                          </video>
                        ) : (
                          <ProgressiveImage
                            src={mediaToUse}
                            placeholder='product-img'
                            onError={() => {
                              setRenderOptimizedMedia(false);
                              console.log('Optimized Image Error');
                            }}
                          >
                            {(src: string, loading: boolean) => {
                              return loading ? (
                                <div className='flex min-h-full max-h-full w-full bg-transparent' />
                              ) : (
                                <img
                                  src={src}
                                  alt='product-thumbnail'
                                  className={`absolute left-1.5 w-[54px] h-[54px] self-center justify-self-start object-cover ${getCornerRadius()}`}
                                />
                              );
                            }}
                          </ProgressiveImage>
                        )
                      ) : null
                    }
                  />
                );
              case PossibleDigitalModulesType.EXPERIENCE_INDEX:
                const expIndex: ExperienceIndexModuleData =
                  module.moduleData as ExperienceIndexModuleData;
                return (
                  <SearchBar
                    placeholder={t('searchBarPlaceholder')}
                    bottomOffset={socials && validateSocials(socials) ? 92 : 0}
                    background={expIndex.backgroundColor}
                    results={expIndex.productArray}
                    buttonStyle={buttonStyle || ''}
                    color={expIndex.color}
                  />
                );
              case PossibleDigitalModulesType.EXPERIENCE:
                const experience: ExperienceModuleData =
                  module.moduleData as ExperienceModuleData;
                return (
                  <ProductButton
                    title={experience.text}
                    buttonStyle={buttonStyle}
                    textColor={experience.color}
                    mediaLink={experience.image || ''}
                    background={experience.backgroundColor}
                    hideImage={experience.hideImage || !experience.image}
                    onClick={() =>
                      redirectToExternalLink(
                        `${window.location.protocol}//${window.location.host}/c/${experience.slug}`,
                        '_self',
                        '',
                        false
                      )
                    }
                  />
                );
              default:
                return null;
            }
          })}
        </div>
        {socials && validateSocials(socials) && (
          <div className='relative w-full h-[5.688rem] flex items-center bg-transparent border-solid border-0 border-t border-white/20'>
            <div
              className={`w-full ${desktopView ? 'max-w-[50%] mx-auto' : ''}`}
            >
              <SocialIcons socials={socials} darkIcons />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DigitalHub;
