import React, { useEffect } from 'react';
import { UrlParam } from 'types/Misc';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useGlobal } from 'context/global/GlobalContext';
import { useParams, useLocation } from 'react-router-dom';
import LoadingIndicator from 'components/atomic/LoadingIndicator';
import PageWrapper from 'components/layout/PageWrapper';
import useRedirectLink from 'hooks/useRedirectLink';
import Button from 'components/atomic/Button';

const TermsAndPrivacy: React.FC = () => {
  const { termsAndPrivacyDetails, termsLoading, setSlug, productDetails } =
    useGlobal();
  const { t } = useTranslation('translation', { keyPrefix: 'termsAndPrivacy' });
  const { id } = useParams<UrlParam>();
  const redirectToExternalLink = useRedirectLink();
  const location = useLocation();

  useEffect(() => {
    if (id) setSlug(id);
  }, [id, setSlug]);

  return (
    <PageWrapper
      allowGoBack={productDetails ? true : false}
      pageTitle={t('pageHeaderTitle')}
    >
      <Helmet>
        <title>{t('pageTitle')}</title>
      </Helmet>
      {termsLoading ? (
        <div className='relative w-full h-full flex items-center justify-center pb-16'>
          <LoadingIndicator />
        </div>
      ) : (
        <div className='relative w-full h-full flex flex-col px-5 py-3 gap-3 overflow-x-hidden overflow-y-auto'>
          <div className='relative w-full flex flex-col pt-1.5 gap-1.5 items-center justify-center text-center'>
            <h2 className='font-semibold'>
              {termsAndPrivacyDetails &&
                `${
                  termsAndPrivacyDetails?.brandLegalName ||
                  termsAndPrivacyDetails?.brandName
                } x `}
              {t('brijLegalName')}
            </h2>
            <span className='text-xxs text-[#636369]'>{t('subtitle')}</span>
          </div>
          <p className='w-full py-1.5 text-left text-xxs leading-[15px] text-black'>
            {t('legalNotice').replaceAll(
              '{{brand_name}}',
              termsAndPrivacyDetails
                ? termsAndPrivacyDetails?.brandLegalName ||
                    termsAndPrivacyDetails?.brandName
                : 'brands'
            )}
            {location.pathname === '/p/BRIJ'
              ? t('legalNoticeBrij')
              : t('legalNoticeMixed').replaceAll(
                  '{{brand_name}}',
                  termsAndPrivacyDetails
                    ? termsAndPrivacyDetails?.brandLegalName ||
                        termsAndPrivacyDetails?.brandName
                    : 'brands'
                )}
          </p>
          {termsAndPrivacyDetails &&
            location.pathname !== '/p/BRIJ' &&
            termsAndPrivacyDetails?.termsAndConditionsURL && (
              <Button
                variant='dark'
                aria-label={`Brand's Terms`}
                title={`${
                  termsAndPrivacyDetails?.brandLegalName ||
                  termsAndPrivacyDetails?.brandName
                } - ${t('termsButton')}`}
                styles='!h-termsButton'
                textStyles='sm:!leading-[18px] md:!leading-5'
                onClick={() =>
                  redirectToExternalLink(
                    termsAndPrivacyDetails?.termsAndConditionsURL,
                    '_blank'
                  )
                }
              />
            )}
          {termsAndPrivacyDetails &&
            location.pathname !== '/p/BRIJ' &&
            termsAndPrivacyDetails?.privacyPolicyURL && (
              <Button
                variant='dark'
                aria-label={`Brand's Privacy Policy`}
                title={`${
                  termsAndPrivacyDetails?.brandLegalName ||
                  termsAndPrivacyDetails.brandName
                } - ${t('privacyButton')}`}
                styles='!h-termsButton'
                textStyles='sm:!leading-[18px] md:!leading-5'
                onClick={() =>
                  redirectToExternalLink(
                    termsAndPrivacyDetails.privacyPolicyURL,
                    '_blank'
                  )
                }
              />
            )}
          <Button
            variant='dark'
            aria-label='Terms'
            title={`Brij Inc. - ${t('termsButton')}`}
            styles='!h-termsButton'
            textStyles='sm:!leading-[18px] md:!leading-5'
            onClick={() =>
              redirectToExternalLink('https://brij.it/terms', '_blank')
            }
          />
          <Button
            variant='dark'
            aria-label='Privacy Policy'
            title={`Brij Inc. - ${t('privacyButton')}`}
            styles='!h-termsButton'
            textStyles='sm:!leading-[18px] md:!leading-5'
            onClick={() =>
              redirectToExternalLink('https://brij.it/privacy-center', '_blank')
            }
          />
          <p className='text-xs text-center font-medium'>
            {t('questionsAboutData')}{' '}
            <a
              className='text-primary underline focus-visible:outline focus-visible:outline-black/60 focus-visible:outline-offset-2'
              href={`mailto:support@brij.it`}
            >
              {t('contactUs')}
            </a>
          </p>
        </div>
      )}
    </PageWrapper>
  );
};

export default TermsAndPrivacy;
