import React, { useState, useEffect, useRef } from 'react';
import { ReactComponent as Chevron } from 'assets/icons/svg/arrow-down.svg';
import { useThemeContext } from 'context/ThemeContext/ThemeContext';
import { CornerStyle } from 'context/ThemeContext/ThemeContext';
import { Scrollbar } from 'react-scrollbars-custom';
import { useTranslation } from 'react-i18next';
import { isPreviewMode } from 'utils/preview';
import useWindowSize from 'hooks/useWindowSize';

type OptionType = {
  value: string;
  label: string;
};

type CustomSelectInputProps = {
  type: 'text' | 'number';
  selected: OptionType | undefined;
  customValueAllowed?: boolean;
  customValueLabel?: string;
  name?: string;
  styles?: string;
  required?: boolean;
  placeholder?: string;
  options: OptionType[] | undefined;
  onChange?: (option: OptionType) => void;
  scrollIntoView?: {
    enabled: boolean;
    parentHeight: number;
    parentContainerId: string;
  };
};

const CustomSelectInput: React.FC<CustomSelectInputProps> = ({
  type,
  name,
  styles,
  options,
  selected,
  required,
  onChange,
  placeholder,
  scrollIntoView,
  customValueLabel,
  customValueAllowed,
}) => {
  const [borderRadius, setBorderRadius] = useState<string>('');
  const [customInput, setCustomInput] = useState<boolean>(false);
  const [showDropdown, toggleDropdown] = useState<boolean>(false);
  const [scrollbarOpacity, setScrollbarOpacity] = useState<number>(0);
  const containerRef = useRef<HTMLButtonElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const windowSize = useWindowSize();
  const { inputStyle } = useThemeContext();
  const { t } = useTranslation('translation', {
    keyPrefix: 'customSelectInput',
  });

  useEffect(() => {
    if (
      !isPreviewMode() &&
      inputRef.current &&
      customInput &&
      (selected?.label === '' || selected?.label === 'NaN')
    )
      inputRef.current.focus();
  }, [selected, customInput]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      )
        toggleDropdown(false);
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (showDropdown) {
      setTimeout(() => setScrollbarOpacity(100), 150);
      if (
        scrollIntoView?.enabled &&
        scrollIntoView?.parentContainerId &&
        containerRef?.current
      ) {
        const bottomSpace =
          scrollIntoView.parentHeight -
          containerRef.current?.getBoundingClientRect().bottom;
        if (bottomSpace < 0)
          document
            .getElementById(scrollIntoView.parentContainerId)
            ?.scrollBy({ top: bottomSpace * -1 + 24, behavior: 'smooth' });
      }
    } else setTimeout(() => setScrollbarOpacity(0), 0);
  }, [showDropdown, scrollIntoView, windowSize, containerRef]);

  useEffect(() => {
    if (inputStyle === CornerStyle.SQUARE_CORNERS) setBorderRadius('0px');
    else if (inputStyle === CornerStyle.ROUNDED_CORNERS)
      setBorderRadius('10px');
    else setBorderRadius('26px');
  }, [inputStyle]);

  const getDropdownHeight = () => {
    if (customValueAllowed) {
      if (options && options.length === 0) return 56;
      else if (options && options.length === 1) return 96;
      else return 136;
    } else {
      if (options && options.length <= 1) return 56;
      else if (options && options.length === 2) return 96;
      else return 136;
    }
  };

  const getCornerStyle = () => {
    switch (inputStyle) {
      case CornerStyle.FULL_ROUND:
        return 'rounded-[26px]';
      case CornerStyle.ROUNDED_CORNERS:
        return 'rounded-[10px]';
      case CornerStyle.SQUARE_CORNERS:
        return 'rounded-none';
      default:
        return 'rounded-[26px]';
    }
  };

  return (
    <button
      type='button'
      ref={containerRef}
      aria-expanded={showDropdown}
      aria-controls='input-dropdown'
      onBlur={() => toggleDropdown(false)}
      onClick={() => !customInput && toggleDropdown(!showDropdown)}
      className={`
        relative w-full h-input flex items-center mt-0.5 border-2 border-solid border-input transition-all ease-linear duration-150 cursor-pointer focus-visible:outline focus-outline:black/60 
        [&_input]:z-20 [&_input]:w-full [&_input]:border-0 [&_input]:bg-transparent 
        [&_input]:active:bg-transparent [&_input]:active:outline-none 
        [&_input]:hover:bg-transparent [&_input]:hover:outline-none 
        ${selected?.label ? 'bg-transparent' : 'bg-white'} 
        ${getCornerStyle()} ${styles}
      `}
      style={{
        borderTopLeftRadius: borderRadius,
        borderTopRightRadius: borderRadius,
        borderBottomLeftRadius: showDropdown ? '0' : borderRadius,
        borderBottomRightRadius: showDropdown ? '0' : borderRadius,
      }}
    >
      <span
        className={`z-40 absolute mx-6 text-muted transition-all duration-150 pointer-events-none ${
          selected?.label
            ? 'text-xxxs px-0.5 bg-white -translate-x-0.5 -translate-y-[25px] opacity-100'
            : 'text-[0.9rem] bg-transparent translate-x-0 translate-y-0 opacity-80'
        }`}
      >
        {placeholder}
      </span>
      <div
        className='w-full relative'
        onClick={() => !customInput && toggleDropdown(!showDropdown)}
      >
        <input
          tabIndex={-1}
          ref={inputRef}
          name={name}
          type={type}
          width='100%'
          maxLength={50}
          min={type === 'number' ? 1 : -9999}
          max={type === 'number' ? 99 : 9999}
          onFocus={(event) => {
            if (!customValueAllowed) {
              event.target.blur();
              toggleDropdown(true);
            }
          }}
          value={selected?.label}
          required={required}
          onChange={(event) => {
            onChange &&
              onChange({ value: 'Other', label: event.target.value as string });
          }}
          onClick={() => !showDropdown && !customInput && toggleDropdown(true)}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (type === 'number' && (event.key === 'e' || event.key === 'E')) {
              event.preventDefault();
            }
          }}
          className={`w-full pl-6 pr-12 text-ellipsis overflow-hidden ${
            customInput ? 'cursor-auto' : 'cursor-pointer pointer-events-none'
          }`}
        />
        <Chevron
          className={`absolute top-0 right-5 cursor-pointer duration-150 ${
            showDropdown ? 'rotate-180' : 'rotate-0'
          }`}
          onClick={() => toggleDropdown(!showDropdown)}
          width={24}
          height={24}
        />
        <ul
          id='input-dropdown'
          className={`absolute z-50 top-[38px] duration-150 shadow-dropdown bg-white ${
            showDropdown
              ? 'w-full left-0 overflow-auto py-2'
              : 'w-[calc(100%-3.25rem)] left-[1.625rem] overflow-hidden'
          }`}
          style={{
            height: showDropdown ? `${getDropdownHeight()}px` : '0px',
            ...{
              borderTopLeftRadius: '0',
              borderTopRightRadius: '0',
              borderBottomLeftRadius: borderRadius,
              borderBottomRightRadius: borderRadius,
            },
          }}
        >
          <Scrollbar
            style={{ background: '#FFFFFF', opacity: scrollbarOpacity }}
          >
            {options &&
              options.length > 0 &&
              options.map((option: OptionType) => (
                <li
                  key={`dropdown-item-${option.value}`}
                  className='w-full h-10 flex items-center text-left px-6 bg-white cursor-pointer hover:bg-lightgray'
                  onClick={() => {
                    toggleDropdown(false);
                    setCustomInput(false);
                    onChange && onChange(option);
                  }}
                >
                  {option.label}
                </li>
              ))}
            {options?.length === 0 && !customValueAllowed && (
              <li
                key='no-result'
                className='w-full h-10 min-h-10 flex items-center px-6 cursor-pointer hover:brightness-110'
              >
                <p className='w-full text-center font-semibold text-sm text-muted opacity-60'>
                  {t('noOptions')}
                </p>
              </li>
            )}
            {customValueAllowed && (
              <li
                key='custom-input-option'
                onClick={() => {
                  toggleDropdown(false);
                  setCustomInput(true);
                  onChange && onChange({ value: 'Other', label: '' });
                }}
                className='w-full h-10 flex items-center text-left px-6 bg-white hover:bg-lightgray cursor-pointer'
              >
                {customValueLabel}
              </li>
            )}
          </Scrollbar>
        </ul>
      </div>
    </button>
  );
};

export default CustomSelectInput;
