// @ts-nocheck
import React from 'react';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { whiteOrBlackTextWithBG } from 'utils/whiteOrBlackTextWithBG';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { useThemeContext } from 'context/ThemeContext/ThemeContext';
import { CornerStyle } from 'context/ThemeContext/ThemeContext';
import DateFnsUtils from '@date-io/moment';

type DatePickerProps = {
  label: string;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
  selectedDate: Date;
  errorMessage?: string;
  changeDate(date: Date): void;
};

const DatePicker: React.FC<DatePickerProps> = ({
  selectedDate,
  errorMessage,
  changeDate,
  disabled,
  minDate,
  maxDate,
  label,
}) => {
  const { brandTheme, inputStyle } = useThemeContext();

  const materialTheme = createTheme({
    overrides: {
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: brandTheme,
        },
      },
      MuiPickersCalendarHeader: {
        switchHeader: {},
      },
      MuiPickersDay: {
        day: {},
        daySelected: {
          color: whiteOrBlackTextWithBG(brandTheme),
          backgroundColor: `${brandTheme} !important`,
        },
        dayDisabled: {
          color: brandTheme,
        },
        current: {},
      },
      MuiPickersModal: {
        dialogAction: {
          color: '#000',
        },
      },
    },
  });

  const getCornerStyle = () => {
    switch (inputStyle) {
      case CornerStyle.FULL_ROUND:
        return 'rounded-full';
      case CornerStyle.ROUNDED_CORNERS:
        return 'rounded-[10px]';
      case CornerStyle.SQUARE_CORNERS:
        return 'rounded-none';
      default:
        return 'rounded-full';
    }
  };

  return (
    <div
      className={`
        relative w-full h-input flex items-center text-[0.9rem] duration-75 border-2 border-solid border-input 
        ${disabled ? 'pointer-events-none opacity-70' : ''} 
        ${getCornerStyle()}
      `}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <span className='z-40 absolute left-6 text-muted transition-all duration-300 pointer-events-none text-xxxs px-0.5 bg-white -translate-y-[25px] opacity-100'>
          {label}
        </span>
        <ThemeProvider theme={materialTheme}>
          <KeyboardDatePicker
            className='[&_.MuiFormHelperText-root]:absolute [&_.MuiFormHelperText-root]:bottom-1.5 [&_.MuiFormHelperText-root]:right-14 date-selector-picker-container'
            maxDateMessage={errorMessage}
            minDateMessage={errorMessage}
            invalidDateMessage={errorMessage}
            style={{
              width: '100%',
              padding: '0 0.5rem 0 1.5rem',
              fontFamily: 'inherit',
            }}
            InputProps={{ disableUnderline: true }}
            variant='inline'
            format='MM/DD/yyyy'
            minDate={minDate}
            maxDate={maxDate}
            margin='none'
            onChange={changeDate}
            value={selectedDate}
            KeyboardButtonProps={{ 'aria-label': 'change date' }}
            autoOk={true}
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default DatePicker;
