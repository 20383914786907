import React, { useEffect } from 'react';
import { LinkModuleType } from 'types/ProductDetailsType';
import ModuleWrapper from 'components/wrappers/ModuleWrapper';
import useRedirectLink from 'hooks/useRedirectLink';

type LinkDrawerProps = {
  drawerTitle: string;
  drawerData: LinkModuleType;
  registrationRequired: boolean;
  closeDrawer: () => void;
};

const LinkDrawer: React.FC<LinkDrawerProps> = ({
  drawerData,
  closeDrawer,
  drawerTitle,
  registrationRequired,
}) => {
  const redirectToExternalLink = useRedirectLink();

  useEffect(() => {
    if (!registrationRequired) {
      redirectToExternalLink(
        drawerData.link.includes('https://') ||
          drawerData.link.includes('http://')
          ? `${drawerData.link}${
              drawerData?.suffix?.enabled ? drawerData.suffix.text : ''
            }`
          : `https://${drawerData.link}${
              drawerData?.suffix?.enabled ? drawerData.suffix.text : ''
            }`,
        '_blank'
      );
      closeDrawer();
    }
  }, [drawerData, closeDrawer, registrationRequired, redirectToExternalLink]);

  return <ModuleWrapper drawerTitle={drawerTitle} />;
};

export default LinkDrawer;
