import { useState, useEffect, useRef, useCallback } from 'react';
import { useCartContext } from 'context/CartContext/CartContext';
import { useAPICacheContext } from 'context/APICacheContext/APICacheContext';
import {
  ModuleInfoType,
  SweepstakesModuleType,
} from 'types/ProductDetailsType';
import { ReactComponent as SweepstakesIcon } from 'assets/icons/svg/sweepstakes.svg';
import { ReactComponent as LoadingIndicator } from 'assets/icons/svg/loading-small.svg';
import { ReactComponent as ExternalLinkIcon } from 'assets/icons/svg/external.svg';
import { ReactComponent as CollectionIcon } from 'assets/icons/svg/collection.svg';
import { ReactComponent as TranslateIcon } from 'assets/icons/svg/translate.svg';
import { ReactComponent as PrivacyIcon } from 'assets/icons/svg/privacy.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/svg/log-out.svg';
import { ReactComponent as ProfileIcon } from 'assets/icons/svg/person.svg';
import { ReactComponent as LoginIcon } from 'assets/icons/svg/log-in.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/svg/trash.svg';
import { ReactComponent as CartIcon } from 'assets/icons/svg/cart.svg';
import { useThemeContext } from 'context/ThemeContext/ThemeContext';
import { CornerStyle } from 'context/ThemeContext/ThemeContext';
import { ignoreIfPreview, isPreviewMode } from 'utils/preview';
import { showToast } from 'components/atomic/Toast/Toast';
import { useGlobal } from 'context/global/GlobalContext';
import { Link, useLocation } from 'react-router-dom';
import { getLocalStorage } from 'utils/storage';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { RoutesHashMap } from 'routes';
import { useAPI } from 'utils/api';
import useFirebase from 'hooks/useFirebase';
import brijLogo from 'assets/logos/svg/brij.svg';
import IconButton from 'components/atomic/IconButton';
import poweredByLogo from 'assets/images/svg/powered-by.svg';

const SideMenu = () => {
  const [isAnimating, setIsAnimating] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const { menuStyle } = useThemeContext();
  const { getAuth, signOut } = useFirebase();
  const { invalidateCache } = useAPICacheContext();
  const { cartProducts, cartQuantity, openCart } = useCartContext();
  const { t } = useTranslation('translation', { keyPrefix: 'sideMenu' });
  const {
    user,
    slug,
    token,
    setSlug,
    logEvent,
    isMenuOpen,
    desktopView,
    setIsNewUser,
    setIsMenuOpen,
    registrationId,
    formRegistration,
    setRegistrationId,
    getPersonalDetails,
    setpersonalDetails,
    sweepstakesGameRules,
    setAutoDeployIsNewUser,
    termsAndPrivacyDetails,
    productDetails: details,
    getCurrentRegistrationId,
  } = useGlobal();
  const auth = getAuth();
  const history = useHistory();
  const location = useLocation();
  const firstRegistrationId = getCurrentRegistrationId();

  const getSweepstakesGameRules = () => {
    if (details) {
      let sweepstakesModule: ModuleInfoType | undefined =
        details?.modules?.find(
          (module) => module.type === 'SWEEPSTAKES_MODULE'
        );
      if (sweepstakesModule) {
        let sweepstakesModuleInfo: SweepstakesModuleType =
          sweepstakesModule.moduleInfo as SweepstakesModuleType;
        return sweepstakesModuleInfo?.gameRules
          ? sweepstakesModuleInfo?.gameRules
          : '';
      } else return '';
    } else return '';
  };

  const handleLogoutButtonClicked = useCallback(() => {
    if (error !== '') setError('');
    // form registraion clean up
    getLocalStorage().removeItem(
      `brij-form-${slug}-${formRegistration?.id}-complete`
    );
    setSlug(null);
    setLoading(true);
    setIsAnimating(false);
    signOut(auth)
      .then(() => {
        setLoading(false);
        showToast({ message: t('signOutToastMessage'), type: 'success' });
        // for now bc of phantom useApi rendender turn global state false.
        setAutoDeployIsNewUser(false);
        setIsNewUser(false);
        invalidateCache();
        getLocalStorage().clear();
        setpersonalDetails(null);
        history.push(RoutesHashMap.Login.path);
      })
      .catch((error) => {
        showToast({ message: error.message, type: 'error' });
      });
    setIsAnimating(false);
  }, [setIsAnimating, auth, error, t, setSlug, invalidateCache]);

  const redirectToCollection = useCallback(async () => {
    await getPersonalDetails();
    invalidateCache();
    showToast({
      message: `${
        details?.product?.displayName || details?.product?.name || slug
      }${t('productRemoved')}`,
      type: 'success',
    });
    setSlug(null);
    setRegistrationId(null);
    setIsAnimating(false);
    getLocalStorage().removeItem(
      `brij-form-${slug}-${formRegistration?.id}-filled`
    );
    history.push(RoutesHashMap.Collection.path);
  }, [
    t,
    slug,
    history,
    setSlug,
    setIsAnimating,
    invalidateCache,
    setRegistrationId,
    getPersonalDetails,
    formRegistration?.id,
    details?.product?.name,
    details?.product?.displayName,
  ]);

  const onRemoveProductError = (error: any) => {
    showToast({ message: error.message, type: 'error' });
    setIsAnimating(false);
  };

  const [removeProduct] = useAPI({
    endpoint: 'user/remove_product',
    method: 'DELETE',
    onSuccess: redirectToCollection,
    onError: onRemoveProductError,
  });

  const showRemoveProductButton =
    details?.product?.registeredToCurrentUser &&
    window.location.pathname.includes(`/c/${slug}`);

  const userToUse = user;
  const showTranslationOption =
    details?.basicTranslationEnabled && !navigator.language.match(/^en/i);
  const translateContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!showTranslationOption) {
      return;
    }
    // Init translation service
    const w: any = window;
    new w.google.translate.TranslateElement(
      { pageLanguage: 'en' },
      translateContainerRef.current
    );
    // Customize translation gadget
    const googleTranslateGadget =
      translateContainerRef.current?.getElementsByClassName(
        'goog-te-gadget'
      )[0];
    const googleTranslateGadgetChilds: any = googleTranslateGadget?.children;
    googleTranslateGadget?.removeChild(googleTranslateGadgetChilds[1]);
    setTimeout(() => {
      const googleTranslateSelector: any =
        googleTranslateGadget?.getElementsByClassName('goog-te-combo')[0];
      googleTranslateSelector.children[0].innerHTML = 'Language';
    }, 1000);
    // Remove top translation bar

    const bodyTopMarginFoundInterval = setInterval(() => {
      const body = document.querySelector('body');
      if (body?.style.top === '40px') {
        body.style.top = '0';
        clearInterval(bodyTopMarginFoundInterval);
      }
    }, 100);
  }, [showTranslationOption]);

  useEffect(() => {
    if (isMenuOpen) setIsAnimating(true);
  }, [isMenuOpen]);

  useEffect(() => {
    if (!isAnimating) setTimeout(() => setIsMenuOpen(false), 300);
  }, [isAnimating, setIsMenuOpen]);

  const websiteUrl = useCallback(() => {
    if (details?.brand.website) {
      return details?.brand.website;
    } else if (termsAndPrivacyDetails?.website) {
      return termsAndPrivacyDetails?.website;
    } else if (sweepstakesGameRules?.website) {
      return sweepstakesGameRules?.website;
    } else return '';
  }, [details, termsAndPrivacyDetails, sweepstakesGameRules]);

  const getCornerStyle = () => {
    switch (menuStyle) {
      case CornerStyle.FULL_ROUND:
        return 'lg:rounded-2xl';
      case CornerStyle.ROUNDED_CORNERS:
        return 'lg:rounded-sm1';
      case CornerStyle.SQUARE_CORNERS:
        return 'lg:rounded-none';
      default:
        return 'lg:rounded-2xl';
    }
  };

  const validateHamburgerOption = (
    optionEnabled: boolean | undefined | null
  ) => {
    if (location.pathname.includes(`/c/${slug}`))
      return optionEnabled ? true : false;
    else return true;
  };

  return !isMenuOpen ? null : (
    <div className='relative w-full h-full'>
      <div
        onClick={() => setIsAnimating(false)}
        className={`absolute z-[99] top-0 right-0 w-full h-full duration-200 bg-black ${
          isAnimating
            ? 'opacity-40 pointer-events-auto'
            : 'opacity-0 pointer-events-none'
        }`}
      />
      <div
        className={`z-[99] absolute w-3/4 max-w-[285px] bg-primary overflow-hidden duration-200 
          ${
            desktopView
              ? `lg:h-[calc(100%_-_3rem)] lg:top-6 ${getCornerStyle()}`
              : 'h-full top-0 rounded-tl-[26px]'
          } 
          ${
            isAnimating
              ? `${desktopView ? 'lg:right-6' : 'right-0'}`
              : '-right-[75%]'
          }
        `}
      >
        <div className='relative w-full h-full flex flex-col items-end pl-3 pr-2 pt-5 pb-7 overflow-auto'>
          <IconButton
            size={18}
            styles='mr-1.5'
            variant='blank'
            iconName='close'
            iconColor='#FFFFFF'
            onClick={() => setIsAnimating(false)}
          />
          <ul className='relative w-full h-full flex flex-col items-end overflow-auto pr-4 pt-3 mt-3 gap-6'>
            {cartProducts && cartProducts.length > 0 && (
              <li>
                <button
                  type='button'
                  className='relative w-full flex flex-row items-start justify-end pl-1 gap-3 leading-6 tracking-[0.1px] text-lg text-white text-right break-words cursor-pointer rounded focus-visible:outline focus-visible:outline-black/60'
                  onClick={() => {
                    openCart();
                    setIsAnimating(false);
                  }}
                >
                  {t('myCart')}
                  <span className='min-w-6'>
                    <CartIcon width={24} fill='#FFFFFF' />
                  </span>
                  <div className='absolute -top-2 -right-2 w-[19px] h-[19px] flex items-center justify-center rounded-full bg-white'>
                    <span className='font-semibold text-[9px] leading-[13px] text-black'>
                      {cartQuantity}
                    </span>
                  </div>
                </button>
              </li>
            )}
            {!userToUse &&
              validateHamburgerOption(
                details?.brand?.webappMenuOptions.signInSignOut
              ) && (
                <Link
                  to={isPreviewMode() ? `/c/${slug}` : RoutesHashMap.Login.path}
                  onClick={() => ignoreIfPreview(() => setIsAnimating(false))}
                  className='pl-1 rounded focus-visible:outline focus-visible:outline-black/60'
                >
                  <li className='w-full flex flex-row items-start justify-end gap-3 leading-6 tracking-[0.1px] text-lg text-white text-right break-words cursor-pointer'>
                    {t('signIn')}
                    <span className='w-6'>
                      <LoginIcon width={24} />
                    </span>
                  </li>
                </Link>
              )}
            {userToUse &&
              validateHamburgerOption(
                details?.brand?.webappMenuOptions.myProfile
              ) && (
                <li>
                  <Link
                    to={
                      isPreviewMode()
                        ? `/c/${slug}`
                        : RoutesHashMap.Profile.path
                    }
                    onClick={() => ignoreIfPreview(() => setIsAnimating(false))}
                    className='w-full flex flex-row items-start justify-end pl-1 gap-3 cursor-pointer rounded focus-visible:outline focus-visible:outline-black/60'
                  >
                    <span className='text-right whitespace-normal mt-px leading-6 tracking-[0.1px] text-lg text-white text-right break-words'>
                      {t('myProfile')}
                    </span>
                    <span className='min-w-6'>
                      <ProfileIcon width={24} />
                    </span>
                  </Link>
                </li>
              )}
            {userToUse &&
              validateHamburgerOption(
                details?.brand?.webappMenuOptions.myCollection
              ) &&
              location.pathname !== RoutesHashMap.Collection.path && (
                <li>
                  <Link
                    to={
                      isPreviewMode()
                        ? `/c/${slug}`
                        : RoutesHashMap.Collection.path
                    }
                    onClick={() => ignoreIfPreview(() => setIsAnimating(false))}
                    className='w-full flex flex-row items-start justify-end pl-1 gap-3 cursor-pointer rounded focus-visible:outline focus-visible:outline-black/60'
                  >
                    <span className='text-right whitespace-normal leading-6 tracking-[0.1px] text-lg text-white text-right break-words'>
                      {t('myCollection')}
                    </span>
                    <span className='min-w-6'>
                      <CollectionIcon width={24} />
                    </span>
                  </Link>
                </li>
              )}
            {showRemoveProductButton &&
              validateHamburgerOption(
                details?.brand?.webappMenuOptions.removeFromCollection
              ) && (
                <li>
                  <button
                    type='button'
                    className='w-full flex flex-row items-start justify-end pl-1 gap-3 cursor-pointer rounded focus-visible:outline focus-visible:outline-black/60'
                    onClick={() =>
                      ignoreIfPreview(() => {
                        removeProduct(
                          {
                            tag: slug,
                            registrationId:
                              registrationId || firstRegistrationId,
                          },
                          token
                        );
                        setIsAnimating(false);
                      })
                    }
                  >
                    <span className='text-right whitespace-normal leading-6 tracking-[0.1px] text-lg text-white text-right break-words'>
                      {t('removeProduct')}
                    </span>
                    <span className='min-w-6'>
                      <TrashIcon width={24} />
                    </span>
                  </button>
                </li>
              )}
            {(window.location.pathname.includes(`/c/`) ||
              window.location.pathname.includes(`/p/`) ||
              window.location.pathname.includes(`/s/`)) &&
              window.location.pathname !== `/p/BRIJ` &&
              validateHamburgerOption(
                details?.brand?.webappMenuOptions.visitWebsite
              ) &&
              websiteUrl && (
                <li>
                  <a
                    className='w-full flex flex-row items-start justify-end pl-1 gap-3 cursor-pointer rounded focus-visible:outline focus-visible:outline-black/60'
                    href={
                      websiteUrl().includes('https://') ||
                      websiteUrl().includes('http://')
                        ? websiteUrl()
                        : `https://${websiteUrl()}`
                    }
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={() =>
                      ignoreIfPreview(() => {
                        setIsAnimating(false);
                        logEvent({
                          eventType: 'ENGAGEMENTS',
                          event: 'WEBSITE_VISITS',
                          data: {
                            url:
                              websiteUrl().includes('https://') ||
                              websiteUrl().includes('http://')
                                ? websiteUrl()
                                : `https://${websiteUrl()}`,
                          },
                        });
                      })
                    }
                  >
                    <span className='text-right whitespace-normal mt-px leading-6 tracking-[0.1px] text-lg text-white text-right break-words'>
                      {t('visitWebsite')}
                    </span>
                    <span className='min-w-6'>
                      <ExternalLinkIcon width={24} />
                    </span>
                  </a>
                </li>
              )}
            {validateHamburgerOption(
              details?.brand.webappMenuOptions.customLinkOne
            ) &&
              details?.brand.webappMenuOptions.customLinkOneContent
                ?.linkUrl && (
                <li>
                  <a
                    className='w-full flex flex-row items-start justify-end pl-1 gap-3 cursor-pointer rounded focus-visible:outline focus-visible:outline-black/60'
                    href={
                      details.brand.webappMenuOptions.customLinkOneContent.linkUrl.includes(
                        'https://'
                      ) ||
                      details.brand.webappMenuOptions.customLinkOneContent.linkUrl.includes(
                        'http://'
                      )
                        ? details.brand.webappMenuOptions.customLinkOneContent
                            .linkUrl
                        : `https://${details.brand.webappMenuOptions.customLinkOneContent.linkUrl}`
                    }
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={() =>
                      ignoreIfPreview(() => {
                        setIsAnimating(false);
                        logEvent({
                          eventType: 'ENGAGEMENTS',
                          event: 'WEBSITE_VISITS',
                          data: details?.brand.webappMenuOptions
                            .customLinkOneContent?.linkUrl
                            ? {
                                url:
                                  details.brand.webappMenuOptions.customLinkOneContent.linkUrl.includes(
                                    'https://'
                                  ) ||
                                  details.brand.webappMenuOptions.customLinkOneContent.linkUrl.includes(
                                    'http://'
                                  )
                                    ? details.brand.webappMenuOptions
                                        .customLinkOneContent.linkUrl
                                    : `https://${details.brand.webappMenuOptions.customLinkOneContent.linkUrl}`,
                              }
                            : '',
                        });
                      })
                    }
                  >
                    <span className='mt-px leading-6 tracking-[0.1px] text-lg text-white text-right break-words'>
                      {
                        details.brand.webappMenuOptions.customLinkOneContent
                          .linkName
                      }
                    </span>
                    <span className='min-w-6'>
                      <ExternalLinkIcon width={24} />
                    </span>
                  </a>
                </li>
              )}
            {validateHamburgerOption(
              details?.brand.webappMenuOptions.customLinkTwo
            ) &&
              details?.brand.webappMenuOptions.customLinkTwoContent
                ?.linkUrl && (
                <li>
                  <a
                    className='w-full flex flex-row items-start justify-end pl-1 gap-3 cursor-pointer rounded focus-visible:outline focus-visible:outline-black/60'
                    href={
                      details.brand.webappMenuOptions.customLinkTwoContent.linkUrl.includes(
                        'https://'
                      ) ||
                      details.brand.webappMenuOptions.customLinkTwoContent.linkUrl.includes(
                        'http://'
                      )
                        ? details.brand.webappMenuOptions.customLinkTwoContent
                            .linkUrl
                        : `https://${details.brand.webappMenuOptions.customLinkTwoContent.linkUrl}`
                    }
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={() =>
                      ignoreIfPreview(() => {
                        setIsAnimating(false);
                        logEvent({
                          eventType: 'ENGAGEMENTS',
                          event: 'WEBSITE_VISITS',
                          data: details?.brand.webappMenuOptions
                            .customLinkTwoContent?.linkUrl
                            ? {
                                url:
                                  details.brand.webappMenuOptions.customLinkTwoContent.linkUrl.includes(
                                    'https://'
                                  ) ||
                                  details.brand.webappMenuOptions.customLinkTwoContent.linkUrl.includes(
                                    'http://'
                                  )
                                    ? details.brand.webappMenuOptions
                                        .customLinkTwoContent.linkUrl
                                    : `https://${details.brand.webappMenuOptions.customLinkTwoContent.linkUrl}`,
                              }
                            : '',
                        });
                      })
                    }
                  >
                    <span className='mt-px leading-6 tracking-[0.1px] text-lg text-white text-right break-words'>
                      {
                        details.brand.webappMenuOptions.customLinkTwoContent
                          .linkName
                      }
                    </span>
                    <span className='min-w-6'>
                      <ExternalLinkIcon width={24} />
                    </span>
                  </a>
                </li>
              )}
            {validateHamburgerOption(
              details?.brand?.webappMenuOptions.termsAndPrivacy
            ) &&
              !location.pathname.includes('/p/') && (
                <Link
                  className='pl-1 rounded focus-visible:outline focus-visible:outline-black/60'
                  to={details ? `/p/${details?.brand?.id}` : '/p/BRIJ'}
                  onClick={() => setIsAnimating(false)}
                >
                  <li className='w-full flex flex-row items-start justify-end gap-3 leading-6 tracking-[0.1px] text-lg text-white text-right break-words cursor-pointer'>
                    {t('termsAndPrivacy')}
                    <span className='w-6'>
                      <PrivacyIcon width={24} />
                    </span>
                  </li>
                </Link>
              )}
            {!location.pathname.includes('/s/') &&
              (getSweepstakesGameRules() ||
                sweepstakesGameRules?.gamerules) && (
                <Link
                  to={`/s/${slug}`}
                  onClick={() => setIsAnimating(false)}
                  className='pl-1 rounded focus-visible:outline focus-visible:outline-black/60'
                >
                  <li className='w-full flex flex-row items-start justify-end gap-3 leading-6 tracking-[0.1px] text-lg text-white text-right break-words cursor-pointer'>
                    {t('sweepstakesRules')}
                    <span className='w-6'>
                      <SweepstakesIcon width={24} />
                    </span>
                  </li>
                </Link>
              )}
            {!!showTranslationOption &&
              validateHamburgerOption(
                details?.brand?.webappMenuOptions.translate
              ) && (
                <li>
                  <button className='relative w-full h-6 flex flex-row items-start justify-end pl-1 gap-3 tracking-[0.1px] text-lg text-white whitespace-nowrap cursor-pointer rounded focus-visible:outline focus-visible:outline-black/60'>
                    <style>
                      {`
                        body > div.skiptranslate {
                          display: block;
                          color: rgba(0, 0, 0, 0);
                        }
                        .goog-te-gadget {
                          color: #ffffff00;
                        }
                        .goog-te-combo {
                          overflow: visible;
                          background: transparent;
                          display: flex !important;
                          text-align: right !important;
                          align-items: flex-end !important;
                          justify-content: flex-end !important;
                          direction: rtl !important;
                          font-family: 'brandFont','Poppins',sans-serif;
                          font-size: 18px;
                          color: #ffffff;
                          cursor: pointer;
                        }
                        select {
                          overflow: visible !important;
                          display: flex !important;
                          align-items: flex-end !important;
                          justify-content: flex-end !important;
                          text-align: right !important;
                          direction: rtl !important;
                          appearance: none !important;
                          margin: 0 !important;
                          color: #FFFFFF !important;
                        }
                        select > option {
                          color: #000000 !important;
                        }
                        option: {
                          width: max-content !important:
                          display: flex !important;
                          align-items: flex-end !important;
                          justify-content: flex-end !important;
                          text-align: right !important;
                          direction: rtl !important;
                        }
                      `}
                    </style>
                    <div
                      ref={translateContainerRef}
                      style={{ transform: 'translateY(-2px)' }}
                    />
                    {!desktopView && (
                      <span className='min-w-6'>
                        <TranslateIcon width={23} />
                      </span>
                    )}
                  </button>
                </li>
              )}
            {userToUse &&
              validateHamburgerOption(
                details?.brand?.webappMenuOptions.signInSignOut
              ) && (
                <li>
                  <button
                    className='w-full flex flex-row items-start justify-end pl-1 gap-3 cursor-pointer rounded focus-visible:outline focus-visible:outline-black/60'
                    onClick={() => ignoreIfPreview(handleLogoutButtonClicked)}
                    type='button'
                  >
                    <span className='mt-px leading-6 tracking-[0.1px] text-lg text-white text-right break-words'>
                      {t('signOut')}
                    </span>
                    {loading ? (
                      <span className='w-6 min-w-6'>
                        <LoadingIndicator />
                      </span>
                    ) : (
                      <span className='w-6 min-w-6'>
                        <LogoutIcon width={24} />
                      </span>
                    )}
                  </button>
                </li>
              )}
          </ul>
          {!details?.brand?.minimizeBranding && (
            <span className='flex flex-row items-baseline pb-1 pt-5 pr-5'>
              {window.location.pathname.includes(`/c/${slug}`) && (
                <img
                  src={poweredByLogo}
                  alt='powered-by'
                  className='w-[111px] pr-2 translate-y-1'
                />
              )}
              <img
                className={`${
                  window.location.pathname.includes(`/c/${slug}`)
                    ? 'w-12'
                    : 'auto'
                }`}
                src={brijLogo}
                alt='brij-logo'
              />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
