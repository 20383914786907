import React, { useState, useReducer, useEffect } from 'react';
import { ShoppingModuleType } from 'types/ProductDetailsType';
import { CartItem } from 'types/ShoppingExperience';
import { getLocalStorage } from 'utils/storage';
import { CartContext } from './CartContext';
import { Position } from 'types/Misc';
interface CartInitStateType {
  cartOpen: boolean;
}

const initState: CartInitStateType = {
  cartOpen: false,
};

const reducer = (state: CartInitStateType, action: any) => {
  switch (action.type) {
    case 'OPEN': {
      return {
        ...state,
        cartOpen: true,
      };
    }
    case 'CLOSE': {
      return {
        ...state,
        cartOpen: false,
      };
    }
    default: {
      return state;
    }
  }
};

export const CartProvider: React.FC = ({ children }) => {
  const [cartProducts, setCartProducts] = useState<CartItem[]>([]);
  const [cartQuantity, setCartQuantity] = useState<number>(0);
  const [cartPosition, setCartPosition] = useState<Position>({
    x: 0,
    y: window.innerHeight,
  });
  const [recommendations, setRecommendations] = useState<ShoppingModuleType[]>(
    []
  );
  const [state, dispatch] = useReducer(reducer, initState);
  const { cartOpen } = state;

  useEffect(() => {
    if (cartProducts && cartProducts.length > 0)
      getLocalStorage().setItem('cart-products', JSON.stringify(cartProducts));
  }, [cartProducts]);

  useEffect(() => {
    if (cartProducts?.length === 0) {
      let recoveredCart: CartItem[] = JSON.parse(
        getLocalStorage().getItem('cart-products')
      );
      if (recoveredCart?.length > 0) setCartProducts([...recoveredCart]);
    }
  }, []);

  useEffect(() => {
    if (cartProducts && cartProducts.length > 0) {
      let totalQuantity: number = 0;
      cartProducts.forEach((product: CartItem) => {
        totalQuantity += product.quantity;
      });
      setCartQuantity(totalQuantity);
    }
  }, [cartProducts]);

  useEffect(() => {
    if (cartOpen) setCartPosition({ ...cartPosition, y: 0 });
  }, [cartOpen]);

  const openCart = () => {
    dispatch({ type: 'OPEN' });
  };

  const closeCart = () => {
    dispatch({ type: 'CLOSE' });
  };

  const clearCart = () => {
    setCartProducts([]);
  };

  return (
    <CartContext.Provider
      value={{
        cartProducts,
        setCartProducts,
        openCart,
        closeCart,
        clearCart,
        cartOpen,
        cartQuantity,
        cartPosition,
        setCartQuantity,
        setCartPosition,
        recommendations,
        setRecommendations,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
