import React from 'react';
import { isIOS } from 'react-device-detect';
import { CountryCodeType } from 'types/Misc';
import { ReactComponent as SearchIcon } from 'assets/icons/svg/search.svg';

type DropdownProps = {
  options: CountryCodeType[];
  invertBorders: boolean;
  borderRadius: string;
  onSelect: (option: CountryCodeType) => void;
};

const Dropdown: React.FC<DropdownProps> = ({
  options,
  onSelect,
  borderRadius,
  invertBorders,
}) => {
  const [countrySearch, setCountrySearch] = React.useState<string>('');

  const borderRadiusStyle = (invertBorders: boolean) => {
    if (invertBorders) {
      return {
        borderTopLeftRadius: borderRadius,
        borderTopRightRadius: borderRadius,
      };
    } else
      return {
        borderBottomLeftRadius: borderRadius,
        borderBottomRightRadius: borderRadius,
      };
  };

  const filteredResults = options.filter((option: CountryCodeType) =>
    option.country.toLowerCase().includes(countrySearch.toLowerCase())
  );

  return (
    <ul
      className={`relative w-full flex flex-col h-full max-h-[186px] py-1.5 overflow-auto duration-300 
      ${invertBorders ? 'border-b-0' : 'border-t-0'}`}
      style={{
        ...{
          border: '1px solid #e7eaeb',
          background: '#FFFFFF',
        },
        ...borderRadiusStyle(invertBorders),
      }}
    >
      <div className='relative w-full max-h-8 flex flex-1 px-2.5 mb-1.5'>
        <SearchIcon
          width={20}
          height={20}
          fill='#9CA3AF'
          className='absolute left-[23px] top-1.5'
        />
        <input
          type='text'
          value={countrySearch}
          placeholder='Search...'
          name='country-search-input'
          onChange={(event) => setCountrySearch(event.target.value)}
          className={`w-full h-searchBar max-h-8 pl-10 pr-2.5 tracking-[0.1px] border-b border-t-0 border-l-0 border-r-0 border-2 border-solid border-lightBorder duration-300 bg-transparent placeholder:opacity-70 focus-visible:outline focus-visible:outline-black/60 ${
            isIOS ? 'text-base' : 'text-sm'
          }`}
        />
      </div>
      {filteredResults?.map((option: CountryCodeType) => (
        <li
          key={option.countryCode}
          onClick={() => onSelect(option)}
          style={{ background: '#FFFFFF' }}
          className='w-full h-[34px] min-h-[34px] flex items-center px-5 cursor-pointer hover:brightness-95'
        >
          <label className='text-2xl cursor-pointer pr-2'>{option.flag}</label>
          <p className='text-xs'>
            {`${option.country} ${
              option.mask.match(/\(\+(\d+)\)/)?.[0]
            }`.replace(/\\/g, '')}
          </p>
        </li>
      ))}
    </ul>
  );
};

export default Dropdown;
