import React, {
  useState,
  useEffect,
  useCallback,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { ModuleButtonType } from 'components/toolbox/ButtonFactory/ButtonFactory';
import { Product, VideoModuleType } from 'types/ProductDetailsType';
import { ReactComponent as Close } from 'assets/icons/svg/close.svg';
import { useHistory, useParams } from 'react-router-dom';
import { useGlobal } from 'context/global/GlobalContext';
import { validateSocials } from 'utils/validateSocials';
import { Scrollbar } from 'react-scrollbars-custom';
import { Position, UrlParam } from 'types/Misc';
import { getLocalStorage } from 'utils/storage';
import { SocialsType } from 'types/SocialsType';
import { isDesktop } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import {
  CornerStyle,
  useThemeContext,
} from 'context/ThemeContext/ThemeContext';
import useWindowSize from 'hooks/useWindowSize';
import LinesEllipsis from 'react-lines-ellipsis';
import AndroidPlayer from 'components/toolbox/AndroidPlayer';
import ButtonFactory from 'components/toolbox/ButtonFactory';
import SocialIcons from 'components/atomic/SocialIcons';
import DragZone from 'components/atomic/DragZone';
import Dragbar from 'components/atomic/Dragbar';
import useHeights from 'hooks/useHeights';

type BottomDrawerProps = {
  logo?: ReactNode;
  title: string | undefined;
  subtitle: string | undefined;
  children: ReactNode;
  isChildOpen: boolean;
  fullWidth: boolean;
  currentPage: number | null;
  closeChild: (closeDrawer?: boolean) => void;
  buttons: ModuleButtonType[] | null;
  socials: SocialsType;
  leadInformation?: ReactNode;
  setMainDrawerOpen: (open: boolean) => void;
  mainDrawerOpen: boolean;
  position: Position;
  setPosition: (position: Position) => void;
  product?: Product;
  allowClose?: boolean;
  openAutoDeployModal?: boolean;
  setOpenAutoDeployModal?: (value: boolean) => void;
  setUserClosedAutoDeployDrawer?: Dispatch<SetStateAction<boolean>>;
  registerProductAgain?: () => void;
  allowSocialIcons: boolean | undefined;
};

const getBottomSpacing = (
  mainDrawerOpen: boolean,
  buttonsLength: number,
  showSocials: boolean,
  allowSocialIcons: boolean | undefined
) => {
  if (!mainDrawerOpen) {
    if (!allowSocialIcons) {
      if (buttonsLength === 0) return 'mt-[120px]';
      else if (buttonsLength === 1) return 'mt-16';
      else return 'mt-0';
    } else if (buttonsLength === 0) {
      if (showSocials) return 'mt-12';
      else return 'mt-[120px]';
    } else if (buttonsLength === 1 && !showSocials) {
      return 'mt-16';
    } else return 'mt-0';
  } else return 'absolute bottom-0 left-0 mt-0';
};

const BottomDrawer: React.FC<BottomDrawerProps> = ({
  logo,
  title,
  buttons,
  socials,
  product,
  subtitle,
  children,
  position,
  closeChild,
  allowClose,
  currentPage,
  isChildOpen,
  setPosition,
  mainDrawerOpen,
  leadInformation,
  allowSocialIcons,
  setMainDrawerOpen,
  openAutoDeployModal,
  registerProductAgain,
  setOpenAutoDeployModal,
  setUserClosedAutoDeployDrawer,
  fullWidth = false,
}) => {
  const [deltaPosition, setDeltaPosition] = useState<number>(0);
  const [isControlled, setIsControlled] = useState<boolean>(true);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [videoSource, setVideoSource] = useState<VideoModuleType | null>(null);
  const { topHeight, bottomHeight } = useHeights(mainDrawerOpen, isChildOpen);
  const { buttonStyle, menuStyle } = useThemeContext();
  const { id } = useParams<UrlParam>();
  const { height } = useWindowSize();
  const history = useHistory();
  const { t } = useTranslation('translation', {
    keyPrefix: 'drawers.bottomDrawer',
  });
  const {
    desktopView,
    productDetails,
    setIsVideoPlaying,
    autoDeployTriggered,
    setAutoDeployTriggered,
  } = useGlobal();

  const { enableMultiRegistration, multiRegistrationCTA } =
    (productDetails?.registration || {}) as any;
  const registeredToCurrentUser =
    productDetails?.product?.registeredToCurrentUser;

  useEffect(() => {
    if ((!isDesktop && !isChildOpen) || (isDesktop && !mainDrawerOpen))
      setPosition({ ...position, y: bottomHeight });
  }, [bottomHeight]);

  useEffect(() => {
    if (mainDrawerOpen) {
      setPosition({ ...position, y: topHeight });
    } else {
      setPosition({ ...position, y: bottomHeight });
      closeChild();
    }
  }, [mainDrawerOpen, bottomHeight, topHeight, closeChild]);

  useEffect(() => {
    if (buttons && !autoDeployTriggered) {
      let autoDeployIndex = -1;
      for (let x = 0; x < buttons.length; x++) {
        if (buttons[x].autoDeploy) {
          autoDeployIndex = x;
          break;
        }
      }
      if (autoDeployIndex > -1 && buttons.length > 2) {
        setTimeout(() => {
          setMainDrawerOpen(true);
          setAutoDeployTriggered(true);
        }, 300);
      }
    }
  }, [setMainDrawerOpen, autoDeployTriggered, setAutoDeployTriggered]);

  const handleDrawerClose = useCallback(() => {
    // whenever we close the drawer route back to main product route
    closeChild(true);
    setIsVideoPlaying(false);
    getLocalStorage().removeItem('brij-current-module-id');
    setMainDrawerOpen(false);
  }, [position, bottomHeight, setMainDrawerOpen]);

  const handleStart = useCallback(() => setIsControlled(false), []);

  const handleDrag = (e: DraggableEvent, data: DraggableData) => {
    setIsDragging(true);
    setPosition({ ...position, y: data.y });

    if (data.deltaY !== 0) {
      setDeltaPosition(data.deltaY);
    }

    if (data.y === bottomHeight) {
      // remove current module id for page refresh
      getLocalStorage().removeItem('brij-current-module-id');
    }
  };

  const handleStop = useCallback(() => {
    setIsDragging(false);
    setIsControlled(true);
    if (deltaPosition > 0) {
      setMainDrawerOpen(false);
    } else if (position.y === bottomHeight) {
      setMainDrawerOpen(false);
    } else setMainDrawerOpen(true);
  }, [topHeight, bottomHeight, deltaPosition, position, setPosition]);

  const getCornerStyle = (style: string) => {
    switch (style) {
      case CornerStyle.FULL_ROUND:
        return 'rounded-tl-[26px] rounded-tr-[26px] rounded-b-0';
      case CornerStyle.ROUNDED_CORNERS:
        return 'rounded-tl-[10px] rounded-tr-[10px] rounded-b-0';
      case CornerStyle.SQUARE_CORNERS:
        return 'rounded-none';
      default:
        return 'rounded-tl-[26px] rounded-tr-[26px] rounded-b-0';
    }
  };

  const getLogoStyle = (buttonStyle: string) => {
    switch (buttonStyle) {
      case CornerStyle.FULL_ROUND:
        return 'rounded-full';
      case CornerStyle.ROUNDED_CORNERS:
        return 'rounded-[10px]';
      case CornerStyle.SQUARE_CORNERS:
        return 'rounded-none';
      default:
        return 'rounded-full';
    }
  };

  const autoDeployButtonExists = buttons?.some(
    (button) => button.autoDeploy === true
  );

  const drawerContent = (
    <>
      {videoSource !== null && (
        <AndroidPlayer source={videoSource} setVideoSource={setVideoSource} />
      )}
      {!desktopView && (
        <div
          className={`
            absolute top-0 left-0 w-full h-full transition-all duration-200 
            ${mainDrawerOpen ? 'bg-black/50' : 'bg-black/0'}
          `}
        />
      )}
      <Draggable
        axis='y'
        bounds={{ top: topHeight, bottom: bottomHeight }}
        position={position}
        onStart={handleStart}
        onDrag={handleDrag}
        onStop={handleStop}
        cancel='a, button, .not-draggable'
        disabled={!allowClose}
      >
        <div
          id='bottom-drawer-closed-container'
          className={`absolute left-0 z-20 flex flex-col items-center justify-start bg-white bottom-0 
            ${
              desktopView
                ? 'xl:left-[240px] xl:w-[calc(100%-240px)] xl:h-full xl:rounded-none xl:shadow-none'
                : `left-0 w-full h-[88%] shadow-drawer ${getCornerStyle(
                    menuStyle
                  )}`
            }
            ${isControlled ? 'duration-300' : 'duration-0'} 
          `}
        >
          {!desktopView && (
            <>
              <div className='z-20 w-full pt-2 calculate-this-children'>
                <Dragbar />
                {!isChildOpen && (
                  <div
                    className={`w-full h-14 flex flex-row items-center justify-between px-6 duration-300 
                    ${isChildOpen ? 'flex' : 'none'}`}
                  >
                    <div
                      className={`h-full flex flex-col justify-center ${
                        leadInformation ? 'w-2/3' : 'w-full'
                      }`}
                    >
                      {title && (
                        <LinesEllipsis
                          trimRight
                          text={title ? title : ''}
                          maxLine={subtitle ? 1 : 2}
                          basedOn='words'
                          ellipsis='...'
                          className='w-full font-semibold text-base leading-5'
                        />
                      )}
                      {subtitle && (
                        <LinesEllipsis
                          trimRight
                          text={subtitle}
                          maxLine={leadInformation ? 1 : 2}
                          basedOn='words'
                          ellipsis='...'
                          className='text-xs text-gray'
                        />
                      )}
                    </div>
                    {mainDrawerOpen ? null : leadInformation}
                  </div>
                )}
                <div>
                  {!mainDrawerOpen && (
                    <div className='w-full flex flex-col px-5 py-2 gap-4'>
                      {buttons?.map((button, index) => {
                        return (
                          index < (buttons.length > 2 ? 1 : 2) && (
                            <>
                              <ButtonFactory
                                key={index}
                                buttonData={{
                                  ...button,
                                  className: 'main-collapsed-drawer-button',
                                }}
                                setVideoSource={setVideoSource}
                                type={button.moduleType}
                                variant={index === 0 ? 'dark' : 'light'}
                                onClick={() => {
                                  if (
                                    button.moduleType === 'DOCUMENT_MODULE' ||
                                    button.moduleType === 'VIDEO_MODULE' ||
                                    button.moduleType === 'LINK_MODULE'
                                  ) {
                                    setMainDrawerOpen(true);
                                  }
                                  button.onClick();
                                }}
                              />
                            </>
                          )
                        );
                      })}
                      {buttons && buttons.length > 2 && (
                        <ButtonFactory
                          key='more-button'
                          type='moreButton'
                          autoDeployExists={
                            autoDeployButtonExists ? true : false
                          }
                          buttonData={{
                            title: product?.customCTAText,
                            moduleTheme: product?.secondaryButtonTheme
                              ? {
                                  enabled:
                                    product?.secondaryButtonTheme?.enabled,
                                  backgroundColor:
                                    product?.secondaryButtonTheme?.background,
                                  borderColor:
                                    product?.secondaryButtonTheme?.border,
                                  textColor:
                                    product?.secondaryButtonTheme?.text,
                                }
                              : undefined,
                            className: 'collapsed-drawer-more-options-button',
                          }}
                          onClick={() => setMainDrawerOpen(true)}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
              {mainDrawerOpen && allowClose && (
                <button
                  aria-label='Close Drawer'
                  className='z-[100] absolute right-8 top-4 w-8 min-w-8 h-8 min-h-8 flex items-center justify-center rounded-full cursor-pointer bg-drawerClose collapse-bottom-drawer-button focus-visible:outline focus-visible:outline-black/60'
                  onClick={() => {
                    if (isChildOpen) {
                      closeChild(true);
                      getLocalStorage().removeItem('brij-current-module-id');
                      history.push(`/c/${id}`);
                    } else handleDrawerClose();
                  }}
                >
                  <Close fill='#414149' />
                </button>
              )}
            </>
          )}
          <div
            className={`w-full flex flex-col items-center justify-start pb-2.5 gap-2 overflow-x-hidden overflow-y-auto ${
              mainDrawerOpen ? 'h-full' : 'calculate-this-children'
            }`}
          >
            {!desktopView && <DragZone />}
            {mainDrawerOpen &&
              (isChildOpen ? (
                <div className='absolute w-full h-full overflow-auto overflow-x-hidden'>
                  <div
                    className={`not-draggable relative w-full h-full overflow-auto ${
                      fullWidth ? 'px-0 overflow-hidden' : 'px-5'
                    }`}
                    style={{
                      height:
                        desktopView && height ? `${height - 64}px` : '100%',
                      maxHeight: desktopView && height ? '720px' : '100%',
                    }}
                  >
                    {children}
                  </div>
                </div>
              ) : (
                <div className='flex flex-col h-full w-full items-stretch'>
                  <Scrollbar>
                    <div className='relative w-full flex-1 overflow-auto flex flex-col px-5 py-2 gap-4'>
                      {buttons?.map((button, index) => (
                        <ButtonFactory
                          key={index}
                          buttonData={{
                            ...button,
                            className:
                              'expanded-drawer-button ' +
                              (!index ? 'expanded-drawer-main-button' : ''),
                          }}
                          variant={button.isHighlight ? 'dark' : 'light'}
                          setVideoSource={setVideoSource}
                          type={button.moduleType}
                          onClick={() => button.onClick()}
                        />
                      ))}
                      {registeredToCurrentUser && enableMultiRegistration && (
                        <button
                          type='button'
                          onClick={registerProductAgain}
                          className='not-draggable w-full flex items-center justify-center py-1 text-xs font-semibold text-primary text-center underline rounded focus-visible:outline focus-visible:outline-black/60'
                        >
                          {multiRegistrationCTA ||
                            t('multipleRegistrationText')}
                        </button>
                      )}
                    </div>
                  </Scrollbar>
                  {mainDrawerOpen &&
                    !isChildOpen &&
                    allowSocialIcons &&
                    validateSocials(socials) && (
                      <div className='relative pt-1 w-full bg-white'>
                        <SocialIcons socials={socials} />
                      </div>
                    )}
                </div>
              ))}
            <div
              className={`w-full ${getBottomSpacing(
                mainDrawerOpen,
                buttons?.length || 0,
                validateSocials(socials),
                allowSocialIcons
              )}`}
            >
              {(buttons?.length === 1 || buttons?.length === 0) &&
                validateSocials(socials) &&
                allowSocialIcons &&
                !mainDrawerOpen &&
                !isDragging && <SocialIcons socials={socials} />}
            </div>
          </div>
        </div>
      </Draggable>
    </>
  );

  return desktopView ? (
    <div
      className='relative h-full w-full flex flex-row overflow-auto'
      style={{
        height: desktopView && height ? `${height - 64}px` : '100%',
        maxHeight: desktopView && height ? '720px' : '100%',
      }}
    >
      <div
        className={`relative w-[240px] h-full flex flex-col items-center pl-1 gap-6 bg-primary ${
          logo ? 'pt-6' : 'py-0'
        }`}
      >
        {logo ? (
          <div
            className={`w-[70%] mr-1 h-[72px] py-3 px-8 flex items-center justify-center bg-white 
              ${getLogoStyle(buttonStyle)}
            `}
          >
            {logo}
          </div>
        ) : null}
        <ul
          className={`relative w-full flex flex-col pb-8 overflow-auto ${
            logo ? 'mt-0' : '-mt-px'
          }`}
        >
          {buttons?.map((button, index) => (
            <li className='relative pl-0.5' key={`${button.title}-${index}`}>
              <div
                className={`w-full h-[0.5px] my-[0.25px] ${
                  index === 0 &&
                  currentPage &&
                  currentPage > 0 &&
                  !buttons[index].moduleTheme?.enabled &&
                  !buttons[index].moduleTheme?.borderColor
                    ? 'bg-minimalGray'
                    : 'bg-transparent'
                }`}
              />
              <ButtonFactory
                buttonData={{
                  ...button,
                  className:
                    'expanded-drawer-button ' +
                    (!index ? 'expanded-drawer-main-button' : ''),
                }}
                index={index}
                currentPage={currentPage}
                variant={button.isHighlight ? 'dark' : 'light'}
                setVideoSource={setVideoSource}
                type={button.moduleType}
                onClick={() => {
                  if (
                    openAutoDeployModal &&
                    setOpenAutoDeployModal &&
                    setUserClosedAutoDeployDrawer
                  ) {
                    setOpenAutoDeployModal(false);
                    setUserClosedAutoDeployDrawer(true);
                  }
                  button.onClick();
                }}
              />
              <div
                className={`w-full h-[0.5px] my-[0.25px] ${
                  index === currentPage ||
                  index + 1 === currentPage ||
                  (button.moduleTheme?.enabled &&
                    button.moduleTheme?.borderColor &&
                    button.moduleTheme?.borderColor) ||
                  (buttons[index + 1]?.moduleTheme?.enabled &&
                    buttons[index + 1]?.moduleTheme?.borderColor &&
                    buttons[index + 1]?.moduleTheme?.borderColor)
                    ? 'bg-transparent'
                    : button.moduleTheme?.enabled &&
                      button.moduleTheme.borderColor &&
                      buttons[index + 1]?.moduleTheme?.borderColor
                    ? 'bg-primary'
                    : 'bg-minimalGray'
                }`}
              />
            </li>
          ))}
        </ul>
        {registeredToCurrentUser && enableMultiRegistration && (
          <button
            type='button'
            onClick={registerProductAgain}
            className='not-draggable w-full flex items-center justify-center pr-2 mr-1 mt-auto mb-4 text-xxs text-secondary font-semibold underline rounded focus-visible:outline focus-visible:outline-black/60'
          >
            {multiRegistrationCTA || t('multipleRegistrationText')}
          </button>
        )}
      </div>
      {drawerContent}
    </div>
  ) : (
    drawerContent
  );
};

export default BottomDrawer;
