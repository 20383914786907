import { createContext, useContext, Dispatch, SetStateAction } from 'react';
import { ShoppingModuleType } from 'types/ProductDetailsType';
import { CartItem } from 'types/ShoppingExperience';
import { Position } from 'types/Misc';

export type CartContextProps = {
  cartOpen: boolean;
  cartProducts: CartItem[];
  cartQuantity: number;
  setCartQuantity: Dispatch<SetStateAction<number>>;
  setCartProducts: Dispatch<SetStateAction<CartItem[]>>;
  cartPosition: Position;
  setCartPosition: Dispatch<SetStateAction<Position>>;
  recommendations: ShoppingModuleType[];
  setRecommendations: Dispatch<SetStateAction<ShoppingModuleType[]>>;
  openCart: () => void;
  closeCart: () => void;
  clearCart: () => void;
};

export const CartContext = createContext<CartContextProps>({
  cartOpen: false,
  cartProducts: [],
  cartQuantity: 0,
  setCartQuantity: () => {},
  cartPosition: { x: 0, y: 0 },
  setCartPosition: () => {},
  setCartProducts: () => {},
  recommendations: [],
  setRecommendations: () => {},
  openCart: () => {},
  closeCart: () => {},
  clearCart: () => {},
});

export const useCartContext = () => useContext(CartContext);
