import React, { useState, useEffect, useRef } from 'react';
import { ReactComponent as ExpandIcon } from 'assets/icons/svg/expand.svg';
import { ProductSearchResultsType } from 'types/DigitalHubTypes';
import { CornerStyle } from 'context/ThemeContext/ThemeContext';
import { shadeChanger } from 'utils/shadeChanger';
import useElementSize from 'hooks/useElementSize';
import useWindowSize from 'hooks/useWindowSize';
import Dropdown from './Dropdown';

type SearchBarProps = {
  bottomOffset: number;
  results: ProductSearchResultsType[];
  buttonStyle: string;
  placeholder: string;
  background: string;
  color: string;
};

const SearchBar: React.FC<SearchBarProps> = ({
  color,
  results,
  background,
  placeholder,
  buttonStyle,
  bottomOffset,
}) => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [showResults, setShowResults] = useState<boolean>(false);
  const [bottomSpace, setBottomSpace] = useState<number | null>(null);
  const [dropdownSafeArea, setDropdownSafeArea] = useState<number>(0);
  const [openedInverted, setOpenedInverted] = useState<string>('bottom');
  const [borderRadius, setBorderRadius] = useState<string>('');
  const [filteredResults, setFilteredResults] = useState<
    ProductSearchResultsType[]
  >([]);
  const [dropdownRef, { height }] = useElementSize();
  const searchbarRef = useRef<HTMLButtonElement>(null);
  const windowSize = useWindowSize();

  useEffect(() => {
    if (bottomSpace) {
      if (height >= 82) setDropdownSafeArea(height);
      else {
        if (filteredResults.length <= 1) setDropdownSafeArea(72);
        else if (filteredResults.length === 2) setDropdownSafeArea(144);
        else if (filteredResults.length >= 3) setDropdownSafeArea(216);
      }
    }
  }, [height, bottomSpace]);

  useEffect(() => {
    if (!searchQuery) setFilteredResults(results || []);
    else {
      let filtered = results.filter((result) => {
        const name = result?.name;
        if (name) {
          return name.toLowerCase().includes(searchQuery.toLowerCase());
        }
        return false;
      });
      setFilteredResults(filtered);
    }
  }, [searchQuery, results?.length]);

  useEffect(() => {
    const scrollHandler = () => {
      if (searchbarRef.current && windowSize.height) {
        setBottomSpace(
          windowSize.height -
            searchbarRef.current.getBoundingClientRect().y -
            bottomOffset -
            58
        );
      }
    };
    if (bottomSpace) window.addEventListener('scroll', scrollHandler, true);
    else window.addEventListener('scroll', scrollHandler, true);

    return () => window.removeEventListener('scroll', scrollHandler, true);
  }, [bottomSpace, height]);

  useEffect(() => {
    setTimeout(() => {
      if (windowSize.height && searchbarRef.current) {
        setBottomSpace(
          windowSize.height -
            searchbarRef.current.getBoundingClientRect().y -
            bottomOffset -
            58
        );
      }
    }, 500);
  }, [windowSize, searchbarRef, height]);

  useEffect(() => {
    if (bottomSpace) {
      if (showResults)
        setOpenedInverted(bottomSpace > dropdownSafeArea ? 'bottom' : 'top');
      else
        setOpenedInverted(
          openedInverted === 'bottom'
            ? bottomSpace < dropdownSafeArea
              ? 'top'
              : 'bottom'
            : bottomSpace > dropdownSafeArea
            ? 'bottom'
            : 'top'
        );
    }
  }, [showResults]);

  useEffect(() => {
    if (buttonStyle === CornerStyle.SQUARE_CORNERS) setBorderRadius('0rem');
    else if (buttonStyle === CornerStyle.ROUNDED_CORNERS)
      setBorderRadius('0.625rem');
    else setBorderRadius('1.625rem');
  }, [buttonStyle]);

  const getDropdownHeight = () => {
    if (filteredResults.length <= 1) return 82;
    else if (filteredResults.length === 2) return 150;
    else return 216;
  };

  const getDropdownStyle = (openedInverted: string) => {
    let dropdownStyle =
      openedInverted === 'bottom'
        ? {
            borderTopLeftRadius:
              bottomSpace && bottomSpace < dropdownSafeArea
                ? borderRadius
                : '0',
            borderBottomLeftRadius:
              bottomSpace && bottomSpace > dropdownSafeArea
                ? borderRadius
                : '0',
            borderTopRightRadius:
              bottomSpace && bottomSpace < dropdownSafeArea
                ? borderRadius
                : '0',
            borderBottomRightRadius:
              bottomSpace && bottomSpace > dropdownSafeArea
                ? borderRadius
                : '0',
            top:
              bottomSpace && bottomSpace > dropdownSafeArea
                ? '57px'
                : `-${getDropdownHeight() - 6}px`,
          }
        : {
            borderTopLeftRadius:
              bottomSpace && bottomSpace < dropdownSafeArea
                ? borderRadius
                : '0',
            borderBottomLeftRadius:
              bottomSpace && bottomSpace > dropdownSafeArea
                ? borderRadius
                : '0',
            borderTopRightRadius:
              bottomSpace && bottomSpace < dropdownSafeArea
                ? borderRadius
                : '0',
            borderBottomRightRadius:
              bottomSpace && bottomSpace > dropdownSafeArea
                ? borderRadius
                : '0',
            bottom:
              bottomSpace && bottomSpace > dropdownSafeArea
                ? `-${getDropdownHeight() - 6}px`
                : '58px',
          };
    return dropdownStyle;
  };

  return (
    <>
      {showResults && (
        <div
          className='z-10 fixed top-0 left-0 w-full h-full bg-transparent'
          onClick={() => setShowResults(false)}
        />
      )}
      <button
        ref={searchbarRef}
        className='z-20 relative w-full focus-visible:outline focus-visible:outline-black/60'
      >
        <input
          tabIndex={-1}
          value={searchQuery}
          placeholder={placeholder}
          onChange={(event) => setSearchQuery(event.target.value)}
          onFocus={() => !showResults && setShowResults(true)}
          style={{
            ...{
              background: background,
              color: color || '#000000',
              border: background
                ? `2px solid ${shadeChanger(background, 30)}`
                : '2px solid #e7eaeb',
            },
            ...{
              borderRadius: borderRadius,
              borderTopLeftRadius: showResults
                ? bottomSpace && bottomSpace < dropdownSafeArea
                  ? '0'
                  : borderRadius
                : borderRadius,
              borderTopRightRadius: showResults
                ? bottomSpace && bottomSpace < dropdownSafeArea
                  ? '0'
                  : borderRadius
                : borderRadius,
              borderBottomLeftRadius: showResults
                ? bottomSpace && bottomSpace < dropdownSafeArea
                  ? borderRadius
                  : '0'
                : borderRadius,
              borderBottomRightRadius: showResults
                ? bottomSpace && bottomSpace < dropdownSafeArea
                  ? borderRadius
                  : '0'
                : borderRadius,
            },
          }}
          className='w-full h-searchBar pr-14 pl-[1.375rem] text-sm tracking-[0.1px] duration-300 shadow-dropdown placeholder:opacity-70'
        />
        <div
          ref={dropdownRef}
          style={{
            ...{
              height: showResults ? `${getDropdownHeight()}px` : '0px',
              background: background,
            },
            ...getDropdownStyle(openedInverted),
          }}
          className={`absolute z-10 duration-300 shadow-dropdown ${
            showResults
              ? 'w-full left-0 overflow-auto -mt-1.5'
              : 'w-[calc(100%-3.25rem)] left-[1.625rem] overflow-hidden'
          }`}
        >
          <Dropdown
            results={filteredResults}
            borderRadius={borderRadius}
            style={buttonStyle}
            background={background}
            color={color}
            invertBorders={
              bottomSpace && bottomSpace < dropdownSafeArea ? true : false
            }
          />
        </div>
        {bottomSpace && (
          <div
            className='absolute top-0 right-2 bottom-0 m-auto h-max p-3 cursor-pointer duration-500 opacity-70'
            onClick={() => setShowResults(!showResults)}
            style={
              bottomSpace
                ? {
                    transform: showResults
                      ? bottomSpace > dropdownSafeArea
                        ? 'rotate(180deg)'
                        : 'rotate(0deg)'
                      : bottomSpace > dropdownSafeArea
                      ? 'rotate(0deg)'
                      : 'rotate(180deg)',
                  }
                : {}
            }
          >
            <ExpandIcon fill={color} />
          </div>
        )}
      </button>
    </>
  );
};

export default SearchBar;
