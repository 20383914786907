import React, { useState, useCallback, useEffect } from 'react';
import { User } from 'firebase/auth';
import { toast } from 'react-toastify';
import { RoutesHashMap } from 'routes';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useGlobal } from 'context/global/GlobalContext';
import { showToast } from 'components/atomic/Toast/Toast';
import { MAGIC_ACTION } from 'context/global/GlobalProvider';
import { ReactComponent as EmailLogo } from 'assets/logos/svg/email.svg';
import { ReactComponent as EmailLogoAlternate } from 'assets/logos/svg/email-alternate.svg';
import { useSuccessDrawerContext } from 'context/SuccessDrawerContext/SuccessDrawerContext';
import useMagicLinkHandler from 'hooks/useMagicLinkHandler';
import ConsentCheck from 'components/atomic/ConsentCheck';
import SocialLogin from 'components/forms/SocialLogin';
import useFirebaseError from 'hooks/useFirebaseError';
import useRecaptchaV3 from 'hooks/useRecaptchaV3';
import useLoginToken from 'hooks/useLoginToken';
import ReCAPTCHA from 'react-google-recaptcha';
import Button from 'components/atomic/Button';
import Input from 'components/atomic/Input';
import useFirebase from 'hooks/useFirebase';
import validator from 'validator';
import envVars from 'env-vars';

type LoginFormProps = {
  brandName?: string;
  handleLogin?: (provider: string, user?: User) => void;
  handleSocialLoading?: (loading: boolean) => void;
  hideSignupOptions?: boolean;
  magicLinkRedirect?: MAGIC_ACTION;
  emailConsentCheck?: boolean;
  emailConsentDetails?: {
    text: string;
    requireConsent: boolean;
    defaultChecked: boolean;
  };
  privacyConsentCheck?: boolean;
  privacyConsentDetails?: {
    text: string;
    requireConsent: boolean;
    defaultChecked: boolean;
  };
  buttonText?: {
    email: string;
    google: string;
  };
};

const LoginForm: React.FC<LoginFormProps> = ({
  handleLogin: handleLoginProp = () => {},
  handleSocialLoading = () => {},
  hideSignupOptions,
  buttonText,
  emailConsentCheck,
  emailConsentDetails,
  privacyConsentCheck,
  privacyConsentDetails,
  magicLinkRedirect = MAGIC_ACTION.NONE,
}) => {
  const [emailRegistration, toggleEmailRegistration] = useState<boolean>(false);
  const [animateFields, toggleAnimateFields] = useState<boolean>(true);
  const [showRecaptcha, setShowRecaptcha] = useState<boolean>(false);
  const [emailConsentError, setEmailConsentError] = useState<boolean>(false);
  const [privacyConsentError, setPrivacyConsentError] =
    useState<boolean>(false);
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isHuman, setHuman] = useState<boolean>(true);
  const [username, setUsername] = useState<string>('');

  const { t } = useTranslation('translation', { keyPrefix: 'signIn' });
  const {
    emailConsent,
    toggleEmailConsent,
    privacyConsent,
    togglePrivacyConsent,
  } = useGlobal();
  const { getAuth, signInWithCustomToken } = useFirebase();
  const { closeSuccessDrawer } = useSuccessDrawerContext();
  const { handleMagicLink, loading: magicLinkLoading } =
    useMagicLinkHandler(username);
  const getErrorMessage = useFirebaseError();
  const isVerified = useRecaptchaV3();
  const location = useLocation();
  const auth = getAuth();

  // sync isVerified with isHuman
  useEffect(() => {
    // if user is not verified show backup recaptcha
    if (!isVerified) setShowRecaptcha(true);
    setHuman(isVerified);
  }, [isVerified]);

  useEffect(() => {
    toggleAnimateFields(!hideSignupOptions);
    toggleEmailRegistration(!!hideSignupOptions);
  }, [hideSignupOptions]);

  useEffect(() => {
    toggleEmailConsent(!!emailConsentDetails?.defaultChecked);
  }, [emailConsentDetails?.defaultChecked, toggleEmailConsent]);

  useEffect(() => {
    togglePrivacyConsent(!!privacyConsentDetails?.defaultChecked);
  }, [privacyConsentDetails?.defaultChecked, togglePrivacyConsent]);

  const onSuccess = useCallback(
    (response) => {
      if (response.existingUser) {
        setLoading(false);
        return handleMagicLink(magicLinkRedirect);
      } else if (response.token) {
        signInWithCustomToken(auth, response.token)
          .then((result) => {
            handleLoginProp('email', result?.user);
          })
          .catch((error) => {
            closeSuccessDrawer();
            showToast({ message: getErrorMessage(error.code), type: 'error' });
            setLoading(false);
          });
      }
    },
    [
      handleMagicLink,
      auth,
      getErrorMessage,
      closeSuccessDrawer,
      handleLoginProp,
      signInWithCustomToken,
    ]
  );

  const [getToken] = useLoginToken(onSuccess);

  const onRecaptchaSuccess = useCallback(() => {
    setHuman(true);
    // dismiss all toast
    toast.dismiss();
  }, []);

  const handleLogin = useCallback(async () => {
    if (!isHuman) {
      showToast({
        message: t('toastMessages.completeRecaptchaVerification'),
        type: 'error',
      });
      return;
    } else if (!validateEmail(username.trim())) {
      showToast({ type: 'error', message: t('toastMessages.invalidEmail') });
      return;
    } else {
      setLoading(true);
      try {
        await getToken({
          email: username,
        });
      } catch (e) {
        // catch error when user exist in firebase but not in our db
        showToast({
          message: t('toastMessages.cantVerifyLoginDetails'),
          type: 'error',
        });
      } finally {
        setEmailSent(true);
      }
      // TODO: Emit gtm event
    }
  }, [t, isHuman, getToken, username]);

  const validateEmailConsent = () => {
    if (emailConsentCheck) {
      if (emailConsentDetails?.requireConsent && !emailConsent) {
        setEmailConsentError(true);
        showToast({
          message: t('toastMessages.emailConsentRequired'),
          type: 'error',
        });
        return false;
      } else return true;
    }
    return true;
  };

  const validatePrivacyConsent = () => {
    if (privacyConsentCheck) {
      if (privacyConsentDetails?.requireConsent && !privacyConsent) {
        setPrivacyConsentError(true);
        showToast({
          message: t('toastMessages.privacyConsentRequired'),
          type: 'error',
        });
        return false;
      } else return true;
    }
    return true;
  };

  const validateEmail = (value: string) =>
    validator.isEmail(value) && !value.toLowerCase().endsWith('.con')
      ? true
      : false;

  const handleUsernameChanged = (value: string) => {
    setUsername(value.trim());
    setEmailSent(false);
  };

  const consentChecks = [];
  if (emailConsentCheck) {
    consentChecks.push({
      error: emailConsentError,
      text: emailConsentDetails?.text || '',
      toggleConsentCheck: toggleEmailConsent,
      consentCheck: emailConsent,
    });
  }
  if (privacyConsentCheck) {
    consentChecks.push({
      error: privacyConsentError,
      text: privacyConsentDetails?.text || '',
      toggleConsentCheck: togglePrivacyConsent,
      consentCheck: privacyConsent,
    });
  }

  return (
    <form
      className={`relative w-full flex flex-col gap-0.5 items-center justify-start text-dark ${
        location.pathname === RoutesHashMap.Login.path ||
        location.pathname === ''
          ? 'py-6 px-5'
          : 'pt-4 p-2'
      }`}
      onSubmit={(event) => {
        event.preventDefault();
        if (emailRegistration)
          validateEmailConsent() && validatePrivacyConsent() && handleLogin();
        else toggleEmailRegistration(true);
      }}
    >
      <div
        className={`relative w-full flex flex-col gap-8 duration-200 overflow-hidden ${
          emailRegistration ? 'h-[58px] pt-1.5 mb-1.5' : 'h-0'
        }`}
      >
        {emailRegistration && (
          <Input
            type='email'
            name='email'
            value={username}
            styles='!h-input'
            aria-label='email'
            autoComplete='email'
            className={'email-registration-input'}
            onChange={(event) => handleUsernameChanged(event.target.value)}
            placeholder={
              username === ''
                ? t('emailInputPlaceholder')
                : t('emailInputFilledPlaceholder')
            }
          />
        )}
      </div>
      {(emailConsentCheck || privacyConsentCheck) && (
        <ConsentCheck
          enabled={[emailConsentCheck || false, privacyConsentCheck || false]}
          errors={[emailConsentError, privacyConsentError]}
          texts={[
            emailConsentDetails?.text || '',
            privacyConsentDetails?.text || '',
          ]}
          toggleConsentChecks={[toggleEmailConsent, togglePrivacyConsent]}
          consentChecks={[emailConsent, privacyConsent]}
        />
      )}
      {showRecaptcha && emailRegistration && (
        <ReCAPTCHA
          sitekey={envVars.REACT_APP_RECAPTCHA_KEY_v2 as string}
          onChange={onRecaptchaSuccess}
        />
      )}
      <Button
        transition
        type='submit'
        styles='!px-12'
        color='#FFFFFF'
        background='#000000'
        loading={loading || magicLinkLoading}
        disabled={emailRegistration && emailSent}
        variant={emailRegistration ? 'custom' : 'black'}
        title={
          emailSent
            ? t('checkYourEmail')
            : buttonText?.email || t('continueWithEmail')
        }
        className={`!mt-0.5 ${
          emailRegistration ? 'submit-email-button' : 'email-login-button'
        }`}
        icon={
          emailRegistration ? (
            <EmailLogoAlternate className='rounded-full absolute left-3' />
          ) : (
            <EmailLogo
              fill='#000000'
              className='rounded-full absolute left-3'
            />
          )
        }
      />
      {emailRegistration ? (
        <Button
          title={t('useDifferentOption')}
          variant='link'
          color='#000000'
          styles='mt-1.5'
          onClick={() => {
            toggleEmailRegistration(false);
            !animateFields && toggleAnimateFields(true);
          }}
        />
      ) : (
        !loading && (
          <SocialLogin
            buttonText={buttonText?.google}
            setLoading={handleSocialLoading}
            validateEmailConsent={validateEmailConsent}
            onSuccess={(user) => handleLoginProp('social', user)}
          />
        )
      )}
    </form>
  );
};

export default LoginForm;
