import React, { useCallback, useEffect, useState } from 'react';
import { validatePhoneNumber } from 'utils/validatePhoneNumber';
import { RequestedPersonalDetailsType } from 'types/ProductDetailsType';
import { useGlobal } from 'context/global/GlobalContext';
import { showToast } from 'components/atomic/Toast/Toast';
import { useTranslation } from 'react-i18next';
import { useAPI } from 'utils/api';
import Input from 'components/atomic/Input';
import Button from 'components/atomic/Button';
import PhoneInput from 'components/atomic/PhoneInput';
import ConsentCheck from 'components/atomic/ConsentCheck';

interface UserUpdatePayload {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  slug?: string | null;
  brandId?: string;
  smsConsent?: {
    consentType: string;
    allowed: boolean;
    consentFor: string;
    language: string;
    defaultLanguage: string;
  };
}

interface PersonalDetailsProps {
  requestedPersonalDetails?: RequestedPersonalDetailsType;
  onPersonalDetailsUpdate?: () => void;
  onDetailsSaved?: () => void;
  saveToShopify?: boolean;
  isNormalLogin?: boolean;
}

const PersonalDetails: React.FC<PersonalDetailsProps> = ({
  onPersonalDetailsUpdate = () => {},
  onDetailsSaved = () => {},
  requestedPersonalDetails,
  saveToShopify,
  isNormalLogin,
}) => {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [smsConsentError, setSmsConsentError] = useState<boolean>(false);
  const { t } = useTranslation('translation', { keyPrefix: 'personalDetails' });
  const {
    slug,
    smsConsent,
    productDetails,
    personalDetails,
    toggleSmsConsent,
    getPersonalDetails,
  } = useGlobal();

  const onSuccess = useCallback(() => {
    getPersonalDetails();
    onPersonalDetailsUpdate();
  }, [onPersonalDetailsUpdate, getPersonalDetails]);

  useEffect(() => {
    if (personalDetails) {
      setFirstName(personalDetails?.profile?.firstName || '');
      setLastName(personalDetails?.profile?.lastName || '');
      setPhoneNumber(personalDetails?.profile?.phoneNumber || '');
    }
  }, [personalDetails]);

  useEffect(() => {
    if (
      requestedPersonalDetails?.phoneNumber?.enabled &&
      requestedPersonalDetails?.phoneNumber?.smsMarketingConsent?.enabled &&
      requestedPersonalDetails?.phoneNumber?.smsMarketingConsent?.requireConsent
    )
      toggleSmsConsent(true);
  }, [requestedPersonalDetails, toggleSmsConsent]);

  const [updateUser, loading] = useAPI<UserUpdatePayload>({
    method: 'PUT',
    endpoint: 'auth/update',
    onSuccess,
  });

  const validateUserInformation = useCallback(
    (details: UserUpdatePayload) => {
      console.log('USER DETAILS: ', details);
      if (
        (isNormalLogin && !details?.firstName) ||
        (requestedPersonalDetails?.name && !details?.firstName)
      ) {
        showToast({ message: t('invalidFirstName'), type: 'error' });
        return;
      }
      if (
        (isNormalLogin && !details?.lastName) ||
        (requestedPersonalDetails?.name && !details?.lastName)
      ) {
        showToast({ message: t('invalidLastName'), type: 'error' });
        return;
      }
      if (
        (isNormalLogin &&
          !details.phoneNumber &&
          !validatePhoneNumber(details.phoneNumber)) ||
        (requestedPersonalDetails?.phoneNumber.enabled &&
          details?.phoneNumber === '' &&
          !validatePhoneNumber(details.phoneNumber))
      ) {
        showToast({
          message: t('invalidPhoneNumber'),
          type: 'error',
        });
        return;
      }
      if (
        requestedPersonalDetails?.phoneNumber.smsMarketingConsent.enabled &&
        requestedPersonalDetails.phoneNumber.smsMarketingConsent
          .requireConsent &&
        !smsConsent
      ) {
        setSmsConsentError(true);
        showToast({
          message: t('smsConsentRequired'),
          type: 'error',
        });
        return;
      }
      updateUser({
        ...details,
        phoneNumber: details.phoneNumber
          ? details.phoneNumber.replace(/[\s()-]/g, '')
          : phoneNumber.replace(/[\s()-]/g, ''),
        slug: saveToShopify ? slug : null,
        brandId: productDetails?.brand?.id,
        ...(requestedPersonalDetails?.phoneNumber.enabled && {
          smsConsent: {
            consentType: requestedPersonalDetails?.phoneNumber
              ?.smsMarketingConsent?.enabled
              ? 'explicit'
              : 'implicit',
            consentFor: 'sms',
            allowed: smsConsent,
            language:
              requestedPersonalDetails?.phoneNumber?.smsMarketingConsent
                ?.consentText || '',
            defaultLanguage:
              requestedPersonalDetails?.phoneNumber?.smsMarketingConsent
                ?.consentText || '',
          },
        }),
      });
      onDetailsSaved();
    },
    [
      t,
      slug,
      updateUser,
      smsConsent,
      phoneNumber,
      saveToShopify,
      isNormalLogin,
      onDetailsSaved,
      productDetails,
      requestedPersonalDetails,
    ]
  );

  return (
    <div className='relative w-full h-full flex flex-1 flex-col items-center justify-start overflow-auto pb-16 px-0.5'>
      <div className='w-full flex flex-col items-center justify-center py-1'>
        <p className='w-full pb-3 pl-1 pt-2 text-base text-left font-semibold'>
          {t('completeProfile')}
        </p>
        {isNormalLogin ||
        (requestedPersonalDetails && requestedPersonalDetails?.name) ? (
          <>
            <Input
              type='text'
              styles='my-3'
              name='firstName'
              value={firstName}
              autoComplete='given-name'
              placeholder={t('firstNameInputPlaceholder')}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <Input
              type='text'
              styles='mb-1.5'
              name='lastName'
              value={lastName}
              autoComplete='family-name'
              placeholder={t('lastNameInputPlaceholder')}
              onChange={(e) => setLastName(e.target.value)}
            />
          </>
        ) : null}
        {isNormalLogin ||
        (requestedPersonalDetails &&
          requestedPersonalDetails?.phoneNumber?.enabled) ? (
          <>
            <div className='z-50 relative w-full'>
              <PhoneInput
                bottomOffset={98}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                existingPhoneNumber={personalDetails?.profile.phoneNumber}
                placeholder={t('phoneNumberInputPlaceholder')}
              />
            </div>
            {requestedPersonalDetails?.phoneNumber?.smsMarketingConsent
              ?.enabled && (
              <ConsentCheck
                styles='!mt-1.5 !mb-0.5'
                enabled={[
                  requestedPersonalDetails?.phoneNumber?.smsMarketingConsent
                    ?.enabled || false,
                ]}
                errors={[smsConsentError]}
                texts={[
                  requestedPersonalDetails?.phoneNumber?.smsMarketingConsent
                    ?.consentText || '',
                ]}
                toggleConsentChecks={[toggleSmsConsent]}
                consentChecks={[smsConsent]}
              />
            )}
          </>
        ) : null}
      </div>
      <div className='w-full flex flex-col items-center justify-center mt-1.5 gap-3'>
        <Button
          variant='dark'
          loading={loading}
          title={t('continueButton')}
          onClick={() =>
            validateUserInformation({
              firstName,
              lastName,
              phoneNumber,
            })
          }
        />
        <Button
          variant='light'
          title={t('skip')}
          onClick={onPersonalDetailsUpdate}
        />
      </div>
    </div>
  );
};

export default PersonalDetails;
